[class*="update-"] {
    @extend .sheet;
    position: relative;
    z-index: $modebar;
    padding: $sm-spacing;
    margin: $sm-spacing;
    margin-top: -$base-spacing;

    @include media($small) {
        padding: $base-spacing;
        margin-right: $base-spacing;
        margin-left: $base-spacing;
    }


    .simple-update { //giving this a class in case we want to add different content in here
        margin: 0;

        a {
            color: $dark-text-color;
            border: none;
        }

        time {
            margin: 0;
            display: block;
            color: $base-color;

            @include media($small) {
                display: inline;
                padding-right: $sm-spacing;
            }

        }

    }

}

.update-featured {
    @extend .bg-stripes;

    .flex-container-small {
        flex-direction: row-reverse;
    }

    img {
        margin-bottom: $sm-spacing;
    }

    @include media($small) {
        padding: $lg-spacing 0;

        img {
            padding-left: $lg-spacing;
        }
    }

}

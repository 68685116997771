/*------------------------------------*\
    $BG PATTERNS
\*------------------------------------*/

%bg-map {
    color: $lt-blue-gray;
    background-color: $color-primary-alpha;
    background-image: url("../img/bg-map.png");
    // image source: https://upload.wikimedia.org/wikipedia/commons/f/f9/Russian_Empire_1745_General_Map_(Latin,_HQ).jpg
    background-repeat: repeat;
    background-attachment: fixed;
}

@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: .99; }
}
@-moz-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: .99; }
}
@-webkit-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: .99; }
}
@-ms-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: .99; }
}
@-o-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: .99; }
}

@mixin fade-in ($duration,$delay) {
    -moz-animation: fadein $duration ease-in $delay alternate infinite; /* Firefox */
    -webkit-animation: fadein $duration ease-in $delay alternate infinite; /* Safari and Chrome */
    -o-animation: fadein $duration ease-in $delay alternate infinite; /* Opera */
    animation: fadein $duration ease-in $delay alternate infinite;
}

.beacons {

    i {
        position: absolute;
        z-index: 0;
        background: $gold;
        border-radius: 100px;
        left: 80%;
        top: 7%;
        width: 4px;
        height: 4px;
        box-shadow: 0px 0px 20px 7px rgba($gold, .1);
        opacity: 0;
        @include fade-in(5s,0.5s);
    }

    i:nth-of-type(2) {
        width: 2px;
        height: 2px;
        left: 5%;
        top: 20%;
        box-shadow: 0px 0px 12px 5px rgba($gold, .5);
        @include fade-in(3s,0s);
    }

    i:nth-of-type(3) {
        width: 1px;
        height: 1px;
        left: 99%;
        top: 70%;
        box-shadow: 0px 0px 15px 5px rgba($gold, .3);
        @include fade-in(5s,1s);
    }

    i:nth-of-type(4) {
        width: 3px;
        height: 3px;
        left: 10%;
        top: 90%;
        box-shadow: 0px 0px 9px 5px rgba($gold, .4);
        @include fade-in(5s,1.5s);
    }

    i:nth-of-type(5) {
        width: 1px;
        height: 1px;
        left: 20%;
        top: 78%;
        box-shadow: 0px 0px 15px 5px rgba($gold, .3);
        @include fade-in(5s,2s);
    }

    i:nth-of-type(6) {
        width: 3px;
        height: 3px;
        left: 90%;
        top: 95%;
        box-shadow: 0px 0px 15px 5px rgba($gold, .3);
        @include fade-in(5s,1.5s);
    }

    i:nth-of-type(7) {
        width: 1px;
        height: 1px;
        left: 97%;
        top: 15%;
        box-shadow: 0px 0px 15px 5px rgba($gold, .3);
        @include fade-in(5s,0s);
    }

    i:nth-of-type(8) {
        width: 1px;
        height: 1px;
        left: 3%;
        top: 75%;
        box-shadow: 0px 0px 10px 3px rgba($gold, .3);
        @include fade-in(5s,2.5s);
    }

    i:nth-of-type(9) {
        width: 1px;
        height: 1px;
        left: 10%;
        top: 68%;
        box-shadow: 0px 0px 15px 5px rgba($gold, .3);
        @include fade-in(3s,2s);
    }

    i:nth-of-type(10) {
        width: 3px;
        height: 3px;
        left: 57%;
        top: 5%;
        box-shadow: 0px 0px 15px 5px rgba($gold, .3);
        @include fade-in(3s,3s);
    }

    i:nth-of-type(11) {
        width: 1px;
        height: 1px;
        left: 40%;
        top: 33%;
        box-shadow: 0px 0px 15px 5px rgba($gold, .3);
        @include fade-in(3s,4s);
    }

    i:nth-of-type(12) {
        width: 4px;
        height: 4px;
        left: 44%;
        top: 47%;
        box-shadow: 0px 0px 10px 3px rgba($gold, .3);
        @include fade-in(3s,1.25s);
    }



    i:nth-of-type(13) {
        width: 2px;
        height: 2px;
        left: 63%;
        top: 50%;
        box-shadow: 0px 0px 12px 5px rgba($gold, .5);
        @include fade-in(3s,0s);
    }

    i:nth-of-type(14) {
        width: 1px;
        height: 1px;
        left: 87%;
        top: 83%;
        box-shadow: 0px 0px 15px 5px rgba($gold, .3);
        @include fade-in(5s,1s);
    }

    i:nth-of-type(15) {
        width: 3px;
        height: 3px;
        left: 46%;
        top: 64%;
        box-shadow: 0px 0px 9px 5px rgba($gold, .4);
        @include fade-in(5s,1.5s);
    }

    i:nth-of-type(16) {
        width: 1px;
        height: 1px;
        left: 32%;
        top: 19%;
        box-shadow: 0px 0px 15px 5px rgba($gold, .3);
        @include fade-in(5s,2s);
    }

    i:nth-of-type(17) {
        width: 3px;
        height: 3px;
        left: 11%;
        top: 62%;
        box-shadow: 0px 0px 15px 5px rgba($gold, .3);
        @include fade-in(5s,1.5s);
    }

    i:nth-of-type(18) {
        width: 1px;
        height: 1px;
        left: 44%;
        top: 44%;
        box-shadow: 0px 0px 15px 5px rgba($gold, .3);
        @include fade-in(5s,0s);
    }

    i:nth-of-type(19) {
        width: 1px;
        height: 1px;
        left: 81%;
        top: 33%;
        box-shadow: 0px 0px 10px 3px rgba($gold, .3);
        @include fade-in(5s,2.5s);
    }

    i:nth-of-type(20) {
        width: 1px;
        height: 1px;
        left: 4%;
        top: 12%;
        box-shadow: 0px 0px 15px 5px rgba($gold, .3);
        @include fade-in(3s,2s);
    }

    i:nth-of-type(21) {
        width: 3px;
        height: 3px;
        left: 75%;
        top: 66%;
        box-shadow: 0px 0px 15px 5px rgba($gold, .3);
        @include fade-in(3s,3s);
    }

    i:nth-of-type(22) {
        width: 1px;
        height: 1px;
        left: 21%;
        top: 69%;
        box-shadow: 0px 0px 15px 5px rgba($gold, .3);
        @include fade-in(3s,4s);
    }

    i:nth-of-type(23) {
        width: 4px;
        height: 4px;
        left: 79%;
        top: 88%;
        box-shadow: 0px 0px 10px 3px rgba($gold, .3);
        @include fade-in(3s,1.25s);
    }



    a.easter-egg {
        position: absolute;
        display: block;
        z-index: 0;
        background: $gold;
        border-radius: 100px;
        left: 5%;
        top: 95%;
        width: 3px;
        height: 3px;
        box-shadow: 0px 0px 10px 7px rgba($gold, .1);
        opacity: 0;
        @include fade-in(2s,0s);
        animation-fill-mode: forwards;
    }

}


.bg-stripes {
    position: relative;
    color: $white;
    background: $dk-gray;

    @include media($small) {
        background: $dk-gray url('../svg/stamp.svg') no-repeat bottom right;
    }

    &.news-bg {
        @include media($small) {
            background:
                url('../svg/stamp.svg') bottom right no-repeat,
                url('../svg/news.svg') top left no-repeat,
                $dk-gray
            ;
        }
    }

    a {
        color: $lt-blue-gray;

        &:hover {
            color: $white;
        }
    }

    .stripes {
        width: 8px;
        border-right: 1px solid $lt-blue-gray;
        color: white;
        background: repeating-linear-gradient(
            45deg,
            $lt-blue-gray,
            $lt-blue-gray 40px,
            $dk-gray 40px,
            $dk-gray 80px
        );
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;

        @include media($medium) {
            width: 25px;
            border-right: 3px solid $lt-blue-gray;
        }
    }

    .dots {
        width: 10px;
        background-image: radial-gradient(circle closest-side, $lt-blue-gray 1px, transparent 2px);
        background-position: 0 0, 100% 0, 0 100%;
        background-repeat: repeat-y;
        background-size: .8em 10px;
        position: absolute;
        left: 6px;
        top: 0;
        bottom: 0;

        @include media($medium) {
            left: 26px;
        }

    }

}


.divider {
    width: 100%;
    height: 1px;
    background-color: $lt-gray;

    &.padded {
        margin: $lg-spacing 0;
    }
}

.case-studies-item {
    text-align: center;

    h3 {
        line-height: 1.2;
    }

    a img {
        max-width: 200px;
        margin: 0 auto;
    }

    .section-heading {
        font-size: 24px;
        font-size: 1.5rem;
        margin-top: $sm-spacing;
    }

    p {

        a {
            border-bottom: 1px dotted $link-color;

            &:hover {
                border-style: solid;
            }
        }
    }
}

.case-studies-feature {
    position: relative;
    @include containerCard($color-light-delta);
    overflow: hidden;
    text-align: center;

    img {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        z-index: 1;
        min-width: 100%;
        max-width: none;
        min-height: 100%;
    }

    .case-studies-feature-body {
        position: relative;
        z-index: 2;
        color: $white;
        background-color: rgba(0,0,0,0.8);
        padding: $sm-spacing;
        margin-top: 300px;

        @include media($small) {
            padding: $base-spacing;
            margin-top: 200px;
        }

        .section-heading {
            color: $white;
        }
    }
}

[class*="stat-"] {
    text-align: center;

    .stat-category {
        font-weight: $bold;
        text-transform: uppercase;
        letter-spacing: 1px;
        font-size: em(12);

        @include media($small) {
            font-size: em(14);
        }

    }

}

.stat-bar {
    align-items: flex-start;

    @include media($large) {
        margin-right: $base-spacing;
        margin-left: $base-spacing;
    }

    .stat {
        flex: 0 0 50%;
        margin-bottom: $sm-spacing;

        @include media($small) {
            flex: 0 0 25%;
            margin-bottom: 0;
        }

    }

    .stat-category {
        color: $color-tertiary;
    }

    .number {
        color: $highlight-color;
        font-size: em(40);
        line-height: 1;
        margin-bottom: $tiny-spacing;

        @include media($mobile) {
            font-size: em(60);
        }

        @include media($medium) {
            font-size: em(80);
        }

        span {
            font-size: em(6);
        }

    }

}

.stat-stack {

    .stat {
        padding: $sm-spacing;
        flex: 0 0 50%;

        @include media($small) {
            flex: 0 0 25%;
        }

        @include media($medium) {
            flex: 0 0 100%;
        }

    }

    p.stat-category {
        color: $slate;
    }

    p.number {
        font-size: em(40);

        @include media($mobile) {
            font-size: em(50);
        }

        @include media($large) {
            font-size: em(65);
        }

        span {
            font-size: em(6);
        }

    }

}

.page-anchors {
    border-left: 4px solid $color-light-beta;
    padding: $sm-spacing;
    padding-left: $base-spacing;

    ul {
        @extend .list-reset;

        li {
            margin-bottom: $sm-spacing;

            a {

                &:before {
                    content: '# ';
                }
            }
        }
    }
}

.anchor {

    &:before {
        content: '# ';
        font-weight: normal;

        @include media($medium) {
            position: absolute;
            z-index: $body;
            margin-left: -18px;
        }
    }
}

.benefits-item {
    @include clearfix;
    position: relative;
    text-align: center;
    padding-bottom: $base-spacing;

    @include media($small) {
        display: flex;
        text-align: left;
    }

    &:before {
        content: '';
        position: absolute;
        z-index: $body;
        right: $lg-spacing;
        left: $lg-spacing;
        bottom: 0;
        height: 1px;
        background-color: $color-light-beta;

        @include media($small) {
            content: none;
        }
    }

    .benefits-item-illustration {
        display: block;
        max-width: 90%;
        height: auto;
        margin: 0 auto;
        margin-bottom: $base-spacing;

        @include media($small) {
            width: 50%;
            margin: 0 $base-spacing;
        }

    }

    .benefits-item-body {

        @include media($small) {
            display: flex;
            width: 50%;
            flex-direction: column;
            justify-content: center;
        }

        .section-heading + p {

            @include media($mobile) {
                font-size: 20px;
                font-size: 1.25rem;
            }
        }
    }
}

.page-context {
    position: relative;
    color: $color-tertiary;
    background-color: $color-primary-beta;
    background-image: url("../img/map-bg.png");
    // image source: https://upload.wikimedia.org/wikipedia/commons/f/f9/Russian_Empire_1745_General_Map_(Latin,_HQ).jpg
    background-repeat: repeat;
    background-attachment: fixed;

    a {
        color: $color-primary-zeta;
    }

    p {

        a {
            border-bottom: 1px dotted $color-tertiary;

            &.active {
                color: $white;
                border-bottom: 1px solid $white;
            }
        }
    }

    li {

        a {
            border-bottom-color: transparent;
        }
    }

    .breadcrumbs {
        position: relative;
        top: -$sm-spacing;

        @include media($small) {
            text-align: left;
        }
    }

    form {
        position: relative;
        z-index: 1;
        background-color: $color-primary;
        padding: $sm-spacing;

        @include media($small) {
            padding: $base-spacing;
        }
    }

    .form-heading {
        color: $white;
    }

    .page-title {

        &.hero {
            color: $color-secondary;
        }

        + .page-heading {
            margin-top: $tiny-spacing;
        }
    }

    .page-headline {

        + .page-heading,
        + .metadata {
            margin-top: $sm-spacing;
            margin-bottom: $sm-spacing;
        }
    }

    .page-heading {
        margin-bottom: $tiny-spacing;
    }

    .device {
        position: relative;
        width: 60%;
        max-width: 400px;
        overflow: hidden;
        text-align: center;
        margin: 0 auto;

        .device-bg {

        }

        .device-viewport {
            position: absolute;
            z-index: 1;
            top: 8%;
            left: 0;
            width: 90%;
            margin: 5%;

            img {
                width: 100%;
            }
        }
    }

    .bg-lost {
        position: absolute;
        z-index: $body;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        overflow: hidden;

        svg {
            opacity: 0.32;
            width: 100%;
        }
    }

    .page-context-body {
        text-align: center;
        padding-top: $base-spacing;
        padding-bottom: $base-spacing;

        @include media($small) {
            padding-top: $huge-spacing;
            padding-bottom: $huge-spacing;
        }

        form {
            max-width: 480px;
            box-shadow: 0 0 2px rgba(0,0,0,0.12),
                0 2px 2px rgba(0,0,0,0.24);
            margin: 0 auto;
        }

        .form-field {

            &.input-and-button {
                margin-bottom: 0;

                input,
                input[type="text"],
                input[type="email"],
                input[type="password"],
                input[type="search"],
                input[type="url"] {
                    display: inline-block;
                }
            }
        }
    }

    .page-context-illustration {

        @include media(new-breakpoint($medium)) {
            position: relative;
            padding-top: $base-spacing;
            padding-bottom: $huge-spacing;
            padding-left: 50%;
        }

        .device {
            margin-top: $base-spacing;

            @include media(new-breakpoint($medium)) {
                position: absolute;
                bottom: 0;
                left: $tiny-spacing;
                width: 45%;
                max-width: none;
                margin-top: 0;

                .device-bg {
                    width: 350px;
                    margin: 0 auto;
                }

                .device-viewport {
                    left: 50%;
                    width: 318px;
                    margin: $sm-spacing $sm-spacing auto -159px;
                }

                + form {
                    margin-top: $sm-spacing;
                }
            }
        }
    }

    .page-context-form {

        @include media($small) {
            display: flex;
            align-items: center;
            justify-content: center;

            .page-context-body {
                width: 60%;
                text-align: left;
                margin-right: $base-spacing;
            }

            form {
                width: 50%;
                margin: $base-spacing auto;
            }
        }

        form {
            margin-bottom: $base-spacing;
        }
    }

    .page-context-photo {

        .page-context-body {
            position: relative;
            z-index: 1;

            p {
                color: $white;
            }
        }

        .bg-image {
            position: absolute;
            z-index: 0;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            overflow: hidden;

            &:before {
                content: '';
                position: absolute;
                z-index: 1;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                background-color: rgba(0,0,0,.72);
            }

            img {
                min-width: 100%;
                min-height: 100%;
                max-height: auto;
            }
        }
    }
}

.page-title {
    font-weight: $light;
    font-size: 34px;
    font-size: 2.125rem;
    text-transform: uppercase;
    letter-spacing: 1px;

    &.hero {
        font-size: 5em;
    }

    @include media($mobile-up) {
        font-size: 45px;
        font-size: 2.8125rem;

        &.hero {
            font-size: 5em;
        }
    }

    @include media(new-breakpoint(min-width 1110px)) {
        font-size: 56px;
        font-size: 3.5rem;

        &.hero {
            font-size: 10em;
        }
    }
}

.page-headline {
    font-weight: $light;
    font-size: 24px;
    font-size: 1.5rem;

    @include media($mobile-up) {
        font-size: 34px;
        font-size: 2.125rem;
    }

    @include media($small) {
        font-size: 45px;
        font-size: 2.8125rem;
    }
}

.page-heading {

    @include media($small) {
        font-size: 20px;
        font-size: 1.25rem;
    }
}

.tabs-menu {
    text-align: center;
    border-top: 1px solid $color-light-gamma;
    border-bottom: 1px solid $color-light-gamma;
    background-color: $white;

    ul {
        @extend .list-reset;
        width: 100%;
        overflow-x: scroll;
        white-space: nowrap;

        li {
            display: inline-block;

            a {
                display: inline-block;
                color: $color-primary-epsilon;
                border-bottom-color: transparent;
                padding: $sm-spacing;

                @include media($small) {
                    padding: $sm-spacing $base-spacing;
                }

                svg.iconic {
                    fill: $color-primary-epsilon;
                    margin-right: 4px;
                }

                &:hover {
                    color: $color-primary;

                    svg.iconic {
                        fill: $color-primary;
                    }
                }
            }

            &.active {

                a {
                    color: $color-primary-alpha;
                    border-bottom: 2px solid $color-secondary;

                    svg.iconic {
                        fill: $color-primary-alpha;
                    }
                }
            }
        }
    }
}

.tabs-target {
    display: none;
    margin-bottom: $sm-spacing;

    @include media($medium) {
        margin-bottom: $base-spacing;
    }

    &.active {
        display: block;

        &.flex-container {
            display: flex;
        }
    }
}

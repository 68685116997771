.timestamp {
    // @include typeDelta;
    border: $base-border;
    border-color: $highlight;
    border-width: 2px;
    padding: $sm-spacing;
    background-color: $white;
    display: inline-block;
    margin: $sm-spacing 0;
    position: relative;

    &:after {
        content: ' ';
        border: 4px solid black;
        border-color: transparent transparent $white $white;
        position: absolute;
        bottom: 50%;
        @include left(-4px);
        margin-bottom: -2px;
        @include rotate(45deg);
        @include box-shadow($top: 2px, $left: -2px, $blur: 0px, $spread: 0px, $color: $highlight, $inset: false);
    }
}

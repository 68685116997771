.announcement {
    @include containerBar($color-light-delta);
    line-height: 1.4;
    padding: $sm-spacing;

    @include media($small) {
        padding: $base-spacing;
    }

    time {
        color: $color-dark-alpha;
        font-weight: $bold;
        text-transform: uppercase;
        margin-right: $base-spacing;
    }

    [data-toggle] {

        @include media($small) {
            display: none;
        }

        .button-label {

            + svg.iconic {
                 @include transition(0.3s);
            }
        }

        &.active {

            .button-label {

                + svg.iconic {
                     @include transform(rotate(180deg));
                }
            }
        }
    }
}

.case-studies {

    @include media($medium) {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row-reverse;
    }

    .section-title {

        @include media($medium) {
            flex: 0 0 100%;
        }
    }

    .case-studies-group {

        @include media($medium) {
            flex: 0 0 50%;
        }

        .case-studies-item {

            img {
                max-width: 100px;
            }

            .section-heading {
                display: none;
            }
        }
    }

    .case-studies-feature {

        @include media($medium) {
            flex: 0 0 45%;
            margin-left: $base-spacing;
            align-self: flex-start;
        }

        .case-studies-feature-body {

            @include media($medium) {
                margin-top: 380px;
            }
        }
    }
}

.case-studies-group {
    display: flex;
    flex-wrap: wrap;

    .case-studies-item {
        padding: $sm-spacing;

        @include media($small) {
            flex: 0 0 50%;
            padding: $base-spacing;
        }
    }
}

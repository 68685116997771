@mixin rotate($deg: 90deg) {
    -webkit-transform: rotate($deg);
    -moz-transform: rotate($deg);
    -ms-transform: rotate($deg);
    -o-transform: rotate($deg);
    transform: rotate($deg);
}

@mixin transform-origin($origin: 50% 50% 0) {
    -webkit-transform-origin: $origin;
    -moz-transform-origin: $origin;
    -ms-transform-origin: $origin;
    -o-transform-origin: $origin;
    transform-origin: $origin;
}

@mixin scale($size: 1) {
    -webkit-transform: scale($size);
    -moz-transform: scale($size);
    -ms-transform: scale($size);
    -o-transform: scale($size);
    transform: scale($size);
}

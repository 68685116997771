[class*="block-grid-"] {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    @include containerCard;

    li {
        list-style: none;
        margin: 0;
        border-bottom: 1px solid $border-color;
        border-right: 1px solid $border-color;

        @include media($small) {

        }

    }

    img {
        display: block;
        height: 60px;
        margin: 0 auto $tiny-spacing;

        @include media($small) {
            height: 50px;
        }

    }

}

.block-grid-5 {
    margin: $sm-spacing auto;

    @include media($small) {
        margin: $base-spacing auto;
    }

    li {
        padding: $base-spacing $sm-spacing;
        text-align: center;
        flex: 0 0 50%;

        @include media($small) {
            flex: 0 0 20%;
            padding: ($base-spacing + 10px) $sm-spacing;
        }

    }


}

.block-grid-4 {
    margin: $lg-spacing 0;

    li {
        padding: $base-spacing;
        flex: 0 0 100%;

        @include media($small) {
            flex: 0 0 50%;
        }

        @include media($medium) {
            flex: 0 0 25%;
            padding: $base-spacing $base-spacing 30px;
        }

    }


}

.block-grid-3 {
    margin: $lg-spacing 0;

    li {
        padding: $base-spacing;
        text-align: center;
        flex: 0 0 100%;

        @include media($small) {
            flex: 0 0 50%;
        }

        @include media($medium) {
            flex: 0 0 33.333%;
            padding: $base-spacing $base-spacing 30px;
        }

    }


}

.activity-listing {
    @include direction;
    border: $base-border;
    border-width: 2px;
    background-color: $white;
    padding: $sm-spacing;
    position: relative;
    margin: $sm-spacing 0;

    &:after {
        content: ' ';
        border: 6px solid black;
        border-color: transparent transparent $white $white;
        position: absolute;
        top: 10px;
        @include left(-6px);
        /* transform-origin: 0 0; */
        @include rotate(45deg);
        @include box-shadow($top: 2px, $left: -2px, $blur: 0px, $spread: 0px, $color: rgba(0,0,0,.08), $inset: false);
    }

    h3 {
        margin: 0;
    }

    .listing-summary {
        @include clearfix;

        .listing-avatar {
            margin-right: $sm-spacing;

            + h3 {
                position: relative;
                top: 0.5em;
            }
        }

        h3 {
            font-weight: normal;

            @include media($small) {
                font-size: em(22);
            }
        }
    }

    .listing-headline {
        font-weight: normal;
        font-size: em(34);
    }

    .listing-avatar {
        float: left;
        width: 50px;
        height: 50px;
    }

    .activity-type {
//        @extend .delta;
        margin: $sm-spacing 0 ;
    }

}


//This gives the timeline a layout when it's in a full width column

.timeline-full-width .activity-listing {

    @include media($small) {
        padding: $base-spacing;
    }

    .listing-summary {

        @include media($small) {
             @include span-columns(8);
             @include float(left);
             @include margin-right(2.35765%);
             @include margin-left(0);
        }

    }

    .activity-type {

        @include media($small) {
             @include span-columns(3);
             @include float(right);
        }

    }

}

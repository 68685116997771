.logo-list-12, .logo-list-10, .logo-list-8 {
     justify-content: center;

    .client-logo {
        text-align: center;
        padding: 0 $base-spacing; 
        flex: 0 0 50%;
        min-width: 0;

        img {
            display: inline-block;
        }

        a img:hover {
            opacity: .7;
        }

        a:hover .cta {
            margin-bottom: 7px;
        }

        @include media($mobile) {
            flex: 0 0 25%;
            padding: $base-spacing;
        }

    }

}

.logo-list-12 {

     .client-logo {

        @include media($medium) {
            flex: 0 0 20%;
            min-width: 0;
            padding: $base-spacing;
        }

    }

}



.page-navigation {
    display: block;
    text-align: center;

    [data-toggle] {
        display: none;
        margin: $sm-spacing auto;

        &.init {
            display: inline-block;
            position: relative;
            z-index: 1;

            @include media($small) {
                display: none;
            }

            &.active {

            }
        }
    }

    .toggle-target {

        &.init {
            display: none;
            text-align: left;

            @include media($small-down) {
                position: relative;
                z-index: $top;
                top: -56px;
                @include containerMenu;
                padding: $base-spacing $sm-spacing;
                padding-top: 64px;
            }

            @include media($small) {
                display: block;
                padding-top: $base-spacing;
            }

            &.active {
                display: block;
                z-index: 0;
            }

        }
    }

    .form-field {
        margin-bottom: $base-spacing;
    }

    .page-navigation-header {
        color: $color-dark-beta;
        font-weight: $bold;
        font-size: 14px;
        font-size: .875rem;
        text-transform: uppercase;
        letter-spacing: 1px;
    }

    ul {
        @extend .list-reset;
        margin-bottom: $lg-spacing;

        li {
            font-size: 14px;
            font-size: .875rem;
            margin: $sm-spacing 0;

            &.active {
                font-weight: $bold;

                a {
                    color: $color-dark-alpha;
                    border-left: 4px solid $color-secondary;
                    padding-left: $sm-spacing;
                }
            }

            a {
                border-bottom: none;
            }
        }
    }

    .intercom-button {

        @include media($medium) {
            margin: 0 0 $base-spacing 0;
        }

    }

}

@mixin box-shadow($top: 1px, $left: 0, $blur: 2px, $spread: 0, $color: rgba(0,0,0,.1), $inset: false) {

    @if $inset {
        -webkit-box-shadow:inset $top $left $blur $spread $color;
        box-shadow: inset $left $top $blur $spread $color;
    } @else {
        -webkit-box-shadow: $top $left $blur $spread $color;
        box-shadow: $left $top $blur $spread $color;
    }
}

@mixin no-shadow {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

/*------------------------------------*\
$ICONS
\*------------------------------------*/

svg.iconic {
    width: 1em;
    height: 1em;
}


// required asterisk
span.required:after {
    content: '\2217';
    color: #B00F23;
    font-weight: normal;
    font-size: 1.75em;
}

[class*="layout-"] {

    @include media($small) {
        margin-top: 66px;
    }

    .masthead {
        z-index: $toolbar;

        @include media($small) {
            position: fixed;
            top: 0;
            right: 0;
            left: 0;
        }
    }

    .page-context {

        .page-context-body {

            @include media($small) {

                &.illustration {
                    padding-bottom: 6%;
                }
            }
        }
    }

    [role="main"] {

        .announcement,
        [role="article"] {
            position: relative;
            z-index: $body;
            margin-top: -$tiny-spacing;
            margin-bottom: $sm-spacing;
        }

        [role="article"] {
            margin-bottom: -$tiny-spacing;
        }

        .wrapper {

            .sheet {

                @include media($mobile-down) {
                    margin-right: -$sm-spacing;
                    margin-left: -$sm-spacing;
                }
            }

            + .wrapper {
                margin-top: $base-spacing;
            }
        }

        .results {
            margin: $base-spacing 0;
        }

        .content-box {
            margin-top: $sm-spacing;
            margin-bottom: $sm-spacing;

            @include media($small) {
                margin-top: $base-spacing;
                margin-bottom: $base-spacing;
            }
        }

        section {

            &:nth-of-type(odd) {

                .tabs-menu {
                    border: none;
                    background-color: transparent;
                }
            }

            &:nth-of-type(even) {
                background-color: $white;
            }

            .section-title {
                text-align: center;
            }
        }
    }
}

.placeholder-nav {
    background: $black;
    height: 70px;
    width: 100%;
}

section {
    @include clearfix;
    padding: $base-spacing 0;

    @include media($small) {
        padding: $lg-spacing 0;
    }

/*
    .form-field {
        text-align: center;
    }
*/
}

 article {

    .asset-grid {
        @include clearfix;

        img {
            max-width: 80%;
            margin: $base-spacing auto;

            @include media($small) {
                display: inline-block;
                float: left;
                max-width: 225px;
                max-height: 225px;
                margin: $base-spacing $base-spacing $base-spacing 0;
            }

        }

    }

}

.cols-wrapper {
    @include clearfix;

    @include media($small) {

        [class*="cols-"] {
            float: left;

            &:first-child {
                margin-right: 0;
            }

            &:last-child {
                float: right;
                margin-left: 0;
            }
        }
    }

    &.cols-reverse {

        @include media($small) {

            [class*="cols-"] {

                &:first-child {
                    float: right;
                    margin-right: 0;
                    margin-left: 1%;
                }

                &:last-child {
                    float: left;
                    margin-right: 1%;
                    margin-left: 0;
                }
            }
        }
    }

    .cols-3 {

        @include media($small) {
            width: 24%;
        }
    }

    .cols-4 {

        @include media($small) {
            width: 32.3333333%;
        }
    }

    .cols-6 {

        @include media($small) {
            width: 47%;
        }
    }

    .cols-8 {

        @include media($small) {
            width: 65.6666667%;
        }
    }
}

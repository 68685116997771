.feature {
    position: relative;
    text-align: center;
    margin: $lg-spacing auto;

    @include media($small) {
        display: flex;
        text-align: left;
    }

    .feature-primary {

        @include media($small) {
            display: flex;
            width: 30%;
            flex-direction: column;
            justify-content: center;
            margin-left: $base-spacing;
        }

        p {

            @include media($mobile) {
                font-size: 20px;
                font-size: 1.25rem;
            }
        }
    }

    .feature-illustration {
        max-width: 80%;
        margin: 0 auto;

        @include media($small) {
            width: 50%;
            max-width: none;
            margin: 0 $base-spacing;
        }
    }

    dl,
    ul {
        margin: $sm-spacing 0;
        margin-right: $base-spacing;

        @include media($small) {
            display: flex;
            width: 30%;
            flex-direction: column;
            justify-content: center;
        }

        li {
            position: relative;
            margin-top: 0;
            margin-left: 0;

            @include media($small-down) {
                list-style-type: none;
                padding-bottom: $sm-spacing;

                &:after {
                    content: '';
                    position: absolute;
                    right: 40%;
                    bottom: 0;
                    left: 40%;
                    height: 1px;
                    background-color: $color-light-beta;
                }
            }
        }
    }

    ul {
        margin-left: $base-spacing;

    }

    dt {
        color: $color-dark-beta;
        font-weight: $bold;
        font-size: 16px;
        font-size: 1.142857143rem;

        + dd {
            line-height: 1.357142857;
            margin-top: $tiny-spacing;
            margin-bottom: $base-spacing;
        }
    }

    .tag {
        display: inline-block;
        position: relative;
        top: -2px;
        font-weight: normal;
        font-size: 12px;
        font-size: .857142857rem;
        @include equal-radius;
        background-color: $color-tertiary;
        padding: $tiny-spacing;
        margin-left: $tiny-spacing;
    }

    + .feature {
        padding-top: $lg-spacing;

        &:before {
            content: '';
            position: absolute;
            z-index: $body;
            top: 0;
            right: $lg-spacing;
            left: $lg-spacing;
            height: 1px;
            background-color: $color-light-beta;
        }
    }
}

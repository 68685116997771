.break {
    color: $white;
    background-color: $color-primary;
    padding: $base-spacing 0;

    form {
        padding: 0;
    }

    .form-heading {
        color: $white;

        @include media($small) {
            display: inline-block;
            position: relative;
            top: -10px;
            margin-right: $base-spacing;
            margin-bottom: 0;
        }
    }

    .form-field {

        &.input-and-button {

            @include media($small) {
                display: inline-block;
                min-width: 500px;
            }
        }
    }
}

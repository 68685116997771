.page-footer {
    @extend %bg-map;
    padding: $sm-spacing 0;
    text-align: center;

    @include media($medium) {
        //text-align: left;

        .btn {
            //display: block;
        }

    }

    .logo {
        width: 170px;
        height: 60px;
    }

    a {
        color: $white;

        &:hover {
            color: $lt-gray;
        }

    }

    .menu {

        .menu-actions {
            display: block !important;
        }
    }

    .social-links {
        margin: $sm-spacing 0;

        li {

            svg {

                path {
                    fill: $color-tertiary;
                }
            }

        }
    }
}

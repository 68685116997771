button,
.button {
    display: inline-block;
   outline: none;
   @include direction;
   color: $white;
   font-weight: $bold;
   font-size: 14px;
   font-size: .875rem;
   font-family: $base-font-family;
   text-align: center;
   text-transform: uppercase;
   text-decoration: none;
   line-height: 1.35;
   letter-spacing: 1px;
   border: 0px;
   @include border-top-radius($global-radius);
   @include border-bottom-radius($global-radius);
   background-color: $color-primary-gamma;
   box-shadow: 0 0 2px rgba(0,0,0,0.12),
    0 2px 2px rgba(0,0,0,0.24);
   @include lrswap(padding, $tiny-spacing $sm-spacing);
   @include transition(0.3s);

   &:hover {
      cursor: pointer;
      color: $white;
      background-color: $color-primary-beta;
      box-shadow: 0 0 2px rgba(0,0,0,0.12),
       0 3px 5px rgba(0,0,0,0.24);
   }

   &:active {
      color: $white;
      background-color: $color-primary-alpha;
      @include no-transition;
      box-shadow: 0 0 2px rgba(0,0,0,0.12),
       0 3px 5px rgba(0,0,0,0.24);
   }

   &:focus {
       color: $white;
   }

   &.disabled, &[disabled] {
      cursor: not-allowed;
      opacity: 0.5;
      background-color: $color-light-gamma;
      box-shadow: none;
   }

   &.button-flat {
       @include no-shadow;
       font-weight: normal;

       &:hover {
           box-shadow: 0 0 2px rgba(0,0,0,0.12),
            0 3px 5px rgba(0,0,0,0.24);
       }
   }

}

.button-alpha {
    color: $color-primary-beta;
   background-color: $color-secondary;

   &:hover,
   &:active,
   &:focus {
      color: $color-primary-beta;
      background-color: darken( $color-secondary, 10% );
   }

   &.disabled, &[disabled] {

      &:hover {
          background-color: $color-light-gamma;
          box-shadow: none;
      }
   }

   &.button-flat {

   }

}

.button-beta {
   color: $link-color;
   background-color: $color-light-delta;

   &:hover {
       color: $link-hover-color;
       background-color: darken( $color-light-delta, 10% );
   }

   &.disabled, &[disabled] {

      &:hover {
          background-color: $color-light-delta;
          box-shadow: none;
      }
   }

}

.button-gamma {
    color: $link-color;
    background-color: $white;

    &:hover, &:active {
       color: $link-hover-color;
       background-color: darken( $white, 10% );
   }

   &.disabled, &[disabled] {

      &:hover {
          background-color: $white;
          box-shadow: none;
      }
   }

   &.button-flat {
       //background-color: transparent;

       &:hover, &:active {
           //background-color: transparent;
       }
   }
}

.button-beta,
.button-gamma {

    &:focus {
        color: $black;
    }

}

.btn-big {
    font-size: em(18);
    padding: $base-spacing;

    @include media($small) {
        padding: $base-spacing $lg-spacing;
        font-size: em(20);
    }
}

.button-override {
    width: 100%;
    padding: 0;
    margin: 0;
    background: $white;
    padding: $sm-spacing;
    text-align: center;
    border-bottom: $base-border;
    color: $link-color;
    font-weight: $bold;
    border-radius: 0;

    &:hover {
        background: $off-white-1;
        color: $link-color;
    }

    span:before {
        content:'+';
        padding-right: 5px;
    }

    &.active span:before {
        content:'-';
    }
}

.tl-timeline {
    background-color: $white !important;

    a {
        color: darken( $color-secondary, 35% ) !important;
    }

    p,
    li {
        font-size: 16px !important;
        line-height: 1.5;

        a {
            color: darken( $color-secondary, 35% ) !important;
            text-decoration: none !important;
            border-bottom: 1px dotted darken( $color-secondary, 20% ) !important;

            &:hover {
                color: darken( $color-secondary, 40% ) !important;
                text-decoration: none !important;
                border-bottom-style: solid !important;
                border-bottom-color: darken( $color-secondary, 30% ) !important;
            }
        }
    }

    .tl-text {

        ul {
            padding: 0 !important;
            margin: 16px !important;
        }
    }

    .tl-credit {
        font-size: 16px !important;
    }
}

.tl-text {
    color: $color-dark-beta;

    p {
        color: $color-dark-beta;
    }
}

.tl-slide {
    background-image: url('https://dl.dropboxusercontent.com/u/2164161/Resilience%20timeline/bg-white.png');
    background-position: center 25%;
    background-repeat: no-repeat;
    background-size: cover;

    &.tl-full-color-background {
        background-color: inherit;
        background-image: url('https://dl.dropboxusercontent.com/u/2164161/Resilience%20timeline/bg-trans.png');
    }

    &.tl-full-color-background,
    &.tl-full-image-background {

        a {
            color: lighten( $color-secondary, 25% ) !important;
            border-bottom: 1px dotted lighten( $color-secondary, 25% ) !important;

            &:hover {
                color: lighten( $color-secondary, 15% ) !important;
                text-decoration: none !important;
                border-bottom-style: solid !important;
                border-bottom-color: lighten( $color-secondary, 15% ) !important;
            }
        }
    }

    .tl-slide-background {
        opacity: 1;
    }
}

.tl-timeline {
    font-family: $base-font-family !important;

    h2 {
        margin-bottom: $sm-spacing !important;
    }
}

.tl-layout-landscape {
    margin: 0 !important;
}

.tl-text .tl-headline-date,
.tl-text h3.tl-headline-date {
    display: inline-block;
    color: $color-dark-beta !important;
    font-family: $base-font-family !important;
    font-size: 12px !important;
    @include equal-radius($sm-spacing);
    background-color: $color-secondary !important;
    padding: 2px $tiny-spacing !important;
    margin-bottom: $sm-spacing !important;

    &:empty {
        display: none !important;
    }

    .tl-full-color-background &,
    .tl-full-image-background & {
        color: $color-dark-beta !important;
        text-shadow: none !important;
        box-shadow: 0 1px 1px rgba(0,0,0,.5);
    }
}

.tl-timeline .tl-caption,
.tl-slidenav-previous .tl-slidenav-title,
.tl-slidenav-next .tl-slidenav-title {
    font-size: 12px !important;
    line-height: 1.3 !important;
}

.tl-media .tl-media-twitter blockquote p {
    font-size: 24px !important;
}

.tl-timeaxis {

    .tl-timeaxis-content-container .tl-timeaxis-major .tl-timeaxis-tick {
        color: $color-dark-beta;

        &:before {
            border-left-color: $color-dark-gamma;
        }
    }

    .tl-timeaxis-content-container .tl-timeaxis-minor .tl-timeaxis-tick {
        color: $color-dark-delta;
    }
}

.tl-timegroup {

    .tl-timegroup-message {
        color: $color-dark-gamma;
    }
}

.tl-timemarker.tl-timemarker-active .tl-timemarker-content-container .tl-timemarker-content .tl-timemarker-text h2.tl-headline {
    font-weight: $bold;
}

.tl-timemarker .tl-timemarker-content-container .tl-timemarker-content .tl-timemarker-text h2.tl-headline {
    color: $color-dark-gamma;
}

.listing {

    .postcard {
        margin-bottom: $sm-spacing;

        @include media($small) {
            margin-bottom: $base-spacing;
        }
    }
}

.custom-fieldset {

    [data-toggle],
    .custom-fieldset-helper {
        display: none;
    }

    &.init {
        padding-top: 0;

        &[disabled] {

            legend {
                color: $color-light-alpha;
                border-color: $color-light-beta;

                &:hover {
                    cursor: not-allowed;;
                }
            }
        }

        [data-toggle] {
            display: inline-block;
        }

        legend {
            position: relative;
            @include direction;
            color: $text-color;
            @include typeBase;
            line-height: 1.3;
            text-transform: none;
            letter-spacing: 0;
            @include equal-radius;
            border: $base-border-dark;
            background-color: $color-light-delta;
            padding-right: $huge-spacing;
            margin-bottom: 0;
            @include transition(0.3s);

            &:hover {
                cursor: pointer;
            }

            &.active {
                z-index: $dropdown+1;
                border-color: transparent;
                @include no-shadow;

                .custom-fieldset-value {

                    svg.iconic {
                        visibility: hidden;
                    }
                }
            }

            .custom-fieldset-value {
                font-weight: $bold;
            }

            svg.iconic {
                fill: $color-light-alpha;

                &.chevron {
                    position: absolute;
                    top: 50%;
                    right: $sm-spacing;
                    z-index: $body;
                    margin-top: -5px;
                }
            }

            img.avatar {
                display: inline-block;
                vertical-align: middle;
                width: 20px;
                height: 20px;
                margin: 0 4px;
            }
        }

        input,
        input[type="text"],
        input[type="email"],
        input[type="password"],
        input[type="search"],
        textarea {
            font-size: 14px;

            + button {
                margin-top: 0;
                margin-bottom: $sm-spacing;
            }
        }

        .fieldset-results {
            max-height: 250px;
            overflow-y: auto;
            padding: $tiny-spacing 0;
        }

        .dropdown-trigger {
            cursor: pointer;
        }

        .dropdown-menu {
            top: 0 !important;
            left: 0 !important;
            padding-top: $lg-spacing;

            @include media($medium) {
                min-width: 250px;
            }

            [data-toggle].active {
                display: none;
            }

            .toggle-content {
                display: none;

                &.active {
                    display: block;
                }
            }
        }

        .dropdown-content {
            min-width: 200px;
        }

        [data-fieldgroup-target] {
            display: none;

            &.active {
                display: block;
            }
        }
    }
}

/* ==========
 * = Testing
 * ========== */

 .base-styles {
     position: relative;
     background-color: #999;
     margin-bottom: 20px;
     padding: 20px;
 }

/* background */
#background-position {
    @include background-position(8px 100px);
}
#background-position-left {
    @include background-position(left 100px);
}
#background-position-right {
    @include background-position(right 100px);
}
#background-position-center {
    @include background-position(center 100px);
}

#background-1 {
    @include background(url(../img/ushahidi-logo-black.svg) no-repeat 20px center);
}
#background-1-left {
    @include background(url(../img/ushahidi-logo-black.svg) no-repeat left center);
}
#background-1-right {
    @include background(url(../img/ushahidi-logo-black.svg) no-repeat right center);
}
#background-1-center {
    @include background(url(../img/ushahidi-logo-black.svg) no-repeat center center);
}
#background-2 {
    @include background(#999 url(../img/ushahidi-logo.svg) no-repeat 8px center);
}
#background-2-left {
    @include background(#999 url(../img/ushahidi-logo.svg) no-repeat left center);
}
#background-2-right {
    @include background(#999 url(../img/ushahidi-logo.svg) no-repeat right center);
}
#background-2-center {
    @include background(#999 url(../img/ushahidi-logo.svg) no-repeat center center);
}


/* border */
#border-left {
    @include border-left(3px solid #000); // mixin test
}
#border-right {
    @include border-right(3px solid #000); // mixin test
}


/* border-radius */
#border-top-left-radius {
    @include border-top-left-radius(10px); // mixin test
}
#border-top-right-radius {
    @include border-top-right-radius(10px); // mixin test
}
#border-bottom-left-radius {
    @include border-bottom-left-radius(10px); // mixin test
}
#border-bottom-right-radius {
    @include border-bottom-right-radius(10px); // mixin test
}


/* clear */
#clear-origin-left {
    @include float(left); // mixin test
    @include clear(left); // mixin test
}
#clear-origin-right {
    @include float(right); // mixin test
    @include clear(right); // mixin test
}


/* direction */
#direction {
    @include direction; // mixin test
}


/* float */
#float-origin-left {
    @include float(left); // mixin test
}
#float-origin-right {
    @include float(right); // mixin test
}


/* position */
#left {
    @include left(20px); // mixin test
}
#right {
    @include right(20px); // mixin test
}


/* margin */
#margin-0-8px-16px-24px {
    @include margin(0 8px 16px 24px); // mixin test
}
#margin-left {
    @include margin-left(20px); // mixin test
}
#margin-right {
    @include margin-right(20px); // mixin test
}


/* padding */
#padding-0-8px-16px-24px {
    @include lrswap(padding, 0 8px 16px 24px); // mixin test
}
#padding-left {
    @include padding-left(20px); // mixin test
}
#padding-right {
    @include padding-right(20px); // mixin test
}


/* text */
#text-align-left {
    @include text-align(left); // mixin test // mixin test
}
#text-align-right {
    @include text-align(right); // mixin test // mixin test
}
#text-indent {
    @include text-indent(20px); // mixin test // mixin test

    text-align: left;
}


#cursor-e {
    @include cursor(e-resize);
}
#cursor-ne {
    @include cursor(ne-resize);
}
#cursor-se {
    @include cursor(se-resize);
}
#cursor-w {
    @include cursor(w-resize);
}
#cursor-nw {
    @include cursor(nw-resize);
}
#cursor-sw {
    @include cursor(sw-resize);
}

/* 4 value swaps - used in margin and padding above*/
#lrswap-0-8px-16px-24px {
    /* Should return the same 2 values: */
    @include lrswap(padding, 0 8px 16px 24px); // mixin test
    @include padding(0 8px 16px 24px); // mixin test

    /* Should return the same 2 values: */
    @include lrswap(margin, 0 8px 16px 24px); // mixin test
    @include margin(0 8px 16px 24px); // mixin test
}

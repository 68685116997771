/*------------------------------------*\
    $GRID
\*------------------------------------*/

$grid-columns: 12;     // The number of columns in the grid
$gutter: 1.6888em;     // The width of margin between columns
$max-width: 1024px;    // The max width of the grid

// Breakpoint Widths
$mobile-min-width: (30em) !default; // 480px
$mobile-up-min-width: (30.063em) !default; // 481px
$small-min-width: (48em) !default; // 768px
$medium-min-width: (64em) !default; // 1024px
$large-min-width: (80em) !default; // 1280px
$xlarge-min-width: (90em) !default; // 1440px
$xxlarge-min-width: (120em) !default; // 1920px
$tall-min-height: (650px) !default; // for vertical breakpoints
$xtall-min-height: (850px) !default; // for vertical breakpoints -- taller

// Defined Breakpoints
// http://thoughtbot.github.io/neat-docs/latest/#new-breakpoint
$mobile: new-breakpoint(min-width $mobile-min-width);
$mobile-down: new-breakpoint(max-width $mobile-min-width);
$mobile-up: new-breakpoint(min-width $mobile-up-min-width);
$small-down: new-breakpoint(max-width $small-min-width);
$small: new-breakpoint(min-width $small-min-width);
$medium: new-breakpoint(min-width $medium-min-width);
$large: new-breakpoint(min-width $large-min-width);
$xlarge: new-breakpoint(min-width $xlarge-min-width);
$xxlarge: new-breakpoint(min-width $xxlarge-min-width);
$tall: new-breakpoint(min-width $small-min-width min-height $tall-min-height);
$xtall: new-breakpoint(min-height $xtall-min-height);

/*------------------------------------*\
    $COLORS
\*------------------------------------*/

// color scheme foundation
$color-primary: #373E46; // faded blue
$color-secondary: #FFC334; // gold
$color-tertiary: #C7CCD4; // pale grey

// blacks and dark greys
$color-dark-alpha: #222222;
$color-dark-beta: #444444;
$color-dark-gamma: #666666;
$color-dark-delta: #888888;

// whites and light greys
$color-light-alpha: #959595;
$color-light-beta: #D6D5D2;
$color-light-gamma: #EEEEEE;
$color-light-delta: #FAFAFA;

// primary color spectrum, based on primary color (faded blue)
$color-primary-alpha: #1E232A;
$color-primary-beta: #2B323A;
$color-primary-gamma: #40444A;
$color-primary-delta: #60646B;
$color-primary-epsilon: #8E9297;
$color-primary-zeta: #F1F1F4;

$black: #1E232A;
$white: #ffffff;
$off-white-1: #FBF9F6;
$off-white-2: #EFECE8;
$lt-gray: #E1E1E1;
$dk-gray: #3F4751;
$lt-blue-gray: #C7CCD4;
$slate: #616467;
$blue: #2274B4;
$yellow: #FFC334;
$gold: $yellow;
$red: #de0000;
$green: #4fab2f;

// Text
$text-color: $color-dark-beta;
$base-color: $color-dark-alpha;
$dark-text-color: $dk-gray;
$big-number-color: $dk-gray;
$lt-text-color: $white;
$info-color: $blue;
$highlight-color: $color-secondary;
$label-color: $color-dark-beta;
$confirmation-color: $green;
$error-color: $red;

// Links
$link-color: $color-primary;
$link-hover-color: darken( $link-color, 20% );
$link-color-dk-bg: darken( $lt-gray, 10% );
$link-hover-color-dk-bg: darken( $lt-gray, 20% );
$button-color: $blue;
$button-hover-color: darken( $button-color, 10% );
$button-disabled-color: #D3CFCA;
$big-button-color: $gold;
$big-button-hover-color: darken( $big-button-color, 10% );;

// Backgrounds
$white-bg: $white;
$main-bg-1: $off-white-1;
$main-bg-2: $off-white-2;
$dark-bg: $black;
$dark-gray-bg: $dk-gray;
$lt-gray-bg: lighten( $lt-gray, 5% );
$dark-overlay: rgba(#000000, .7);
$form-bg-color: $dk-gray;
$dk-form-bg-color: darken( $form-bg-color, 5% );

// Borders
$border-color: $color-light-gamma;
$border-color-dark: darken( $border-color, 15% );
$border-color-hover: darken( $border-color, 10% );
$border-color-active: darken( $border-color, 25% );

// Other
$highlight: $yellow;
$sass: #CC6699;


/*------------------------------------*\
    $TYPE
\*------------------------------------*/
$fa-font-path: "../../../fonts" !default;

/*------------------------------------*\
    $TYPE
\*------------------------------------*/
$base-font-family: 'Lato', "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif;
$base-font-color: $base-color;
$sub-base-font-color: $text-color;
$body-font-weight: normal;
$body-font-weight: 400;
$body-font-style: normal;
$bold: 900;
$light: 300;

$base-font-size: 14px;
$base-line-height: 1.266666667;
$small-font-size: em(14);

@mixin typeBase {
    font-weight: 400;
    font-family: $base-font-family;
}


/*------------------------------------*\
    $Z-INDEX
\*------------------------------------*/

$modal: 10000;

$modecontext: 4000;
$modebar: 3000;
$toolbar: 2000;
$dropdown: 1500;
$toolbox: 1000;

$top: 1000;
$topbar: 600;
$overlay: 100;
$bg-img: 50;
$bottom: 0;
$below: -1;
$body: 100;

/*------------------------------------*\
    $MISC
\*------------------------------------*/

//Transition
$transition-duration: 0.3s;

//Layout
$global-radius: 2px;
$base-spacing: 24px;
$tiny-spacing: $base-spacing/3; // 8px
$sm-spacing: $base-spacing/1.5; // 16px
$lg-spacing: $base-spacing*1.333333333; // 32px
$huge-spacing: $base-spacing*1.666666667; // 40px

//Border
$base-border: 1px solid $border-color;
$base-border-dark: 1px solid $border-color-dark;
$base-border-hover: 1px solid $border-color-hover;
$base-border-active: 1px solid $border-color-active;

.pagination, .stepper {
    text-align: center;

    li {
        display: inline-block;
        cursor: pointer;
        font-weight: $bold;
        font-size: 16px;
        padding: 0 10px;

        a {
            border-bottom: none;
        }

        &.active,
        &.active:hover {
            color: $base-color;
            cursor: default;
        }
    }

}

.blog-post-list {

    h3 {
        margin: 0;

        a {
            color: $base-color;
        }

    }

    .flex-container-small {
        align-items: flex-start;
    }

    .post-image {
        max-width: 440px;

        @include media($small) {
            max-width: none;
            padding-right: $lg-spacing;
            flex: 0 0 40%;
            min-width: 0;
        }

    }

    .post-summary {

        @include media($small) {
            flex: 0 0 60%;
            min-width: 0;
        }

        @include media($medium) {
            margin-top: $base-spacing;
        }

    }

    article {
        padding: $base-spacing 0;

        @include media($small) {
            padding: $base-spacing 0; //this overwrites the article padding in layout-general.scss
        }
    }

}

@mixin typeBase {
    font-weight: 400;
    font-size: 16px;
    font-size: 1rem;
}

@mixin typeAlpha {
    font-weight: 900;
    font-size: 20px;
    font-size: 1.428571429rem;
    line-height: 1.3125;

    @include media($small) {
        font-size: 34px;
        font-size: 2.428571429rem;
    }
}

@mixin typeBeta {
    font-weight: 400;
    font-size: 20px;
    font-size: 1.428571429rem;
}

@mixin typeGamma {
    font-weight: 400;
    font-size: 16px;
    font-size: 1.142857143rem;
}

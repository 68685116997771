.img-with-text {

    img {
        width: 85%;
        margin: 0 auto $sm-spacing;
        max-width: 550px;

        @include media($small) {
            margin: $lg-spacing auto;
        }

    }

    .text-content {
        margin: $lg-spacing 0;

        @include media($small) {
            padding-left: $base-spacing;
        }
    }

}
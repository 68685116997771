.menu {
    position: relative;
    text-align: center;
    margin-right: $sm-spacing;
    margin-bottom: $tiny-spacing;
    margin-left: $sm-spacing;

    @include media($small) {
        margin: $sm-spacing $base-spacing;
    }

    @include media($medium) {
        margin-top: $tiny-spacing;
        margin-bottom: $tiny-spacing;
    }

    ul {
        @extend .list-reset;
        display: inline-block;

        li {
            display: inline-block;
            margin-right: $base-spacing;
            margin-bottom: $tiny-spacing;

            a {
                border-bottom-color: transparent;

                &:hover {
                    color: $white;
                }
            }

            &.active {

                a {
                    color: $white;
                    border-bottom: 2px solid $color-secondary;
                    padding-bottom: 4px;
                }
            }

            .button {
                margin-top: $tiny-spacing;
            }
        }

        br {

            @include media($medium) {
                display: none;
            }
        }
    }

    [data-toggle] {
        display: none;

        &.init {
            display: inline-block;
        }

        @include media($mobile-up) {
            margin-right: $sm-spacing;
        }

        .button-label {
            display: none;

            @include media($small) {
                display: inline-block;
            }
        }
    }

    .menu-secondary,
    .menu-actions {

        &.init {
            display: none;

            &.active {
                display: block;
            }

        }

    }

    .menu-secondary {
        margin-top: $tiny-spacing;
    }

    .menu-actions {

        @include media($medium) {
            display: inline-block !important;
        }

        li {
            margin-right: $sm-spacing;
        }
    }

    .menu-dropdown {
        position: relative;

        [data-toggle] {

            svg.iconic,
            .button-label {
                position: relative;
                top: 0;
                z-index: $dropdown + 1;
            }
        }

        .menu-secondary {

            &.init {

                @include media($medium) {
                    display: none;
                    position: absolute;
                    top: -$tiny-spacing;
                    left: 0;
                    z-index: $dropdown;
                    width: 150px;
                    @include containerMenu($color-primary-alpha);
                    text-align: left;
                    padding: $sm-spacing;
                    padding-top: 40px;
                    margin: 0;

                    &.active {
                        display: block;

                        li {
                            width: 110px;
                        }

                    }

                }

            }
        }
    }
}

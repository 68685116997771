.tiers {
    @include clearfix;
    position: relative;
    z-index: $body;
    @include containerSheet;

    .tabs-menu {

        @include media($medium) {
            display: none;
        }
    }

    .tabs-target {

        @include media($medium) {
            display: block !important;
        }

    }

    .tier {

        @include media($medium) {
            position: relative;
            float: left;
            width: 25%;

            &:before {
                content: '';
                position: absolute;
                z-index: 1;
                top: 0;
                right: 0;
                left: 0;
                height: $tiny-spacing;
                background-color: $color-secondary;
            }

            &:after {
                content: '';
                position: absolute;
                z-index: 1;
                top: $base-spacing;
                right: 0;
                bottom: $base-spacing;
                width: 1px;
                background-color: $color-light-beta;
            }

            &:nth-child(2) {

                &:before {
                    background-color: lighten( $color-secondary, 25% );
                }
            }

            &:nth-child(3) {

                &:before {
                    background-color: lighten( $color-secondary, 15% );
                }
            }

            &:nth-child(4) {

                &:before {
                    background-color: lighten( $color-secondary, 5% );
                }
            }

            .tier-name {

                + p {
                    min-height: 45px;
                }
            }
        }
    }

    .tiers-view-all {
        clear: both;
        text-align: center;
        margin: $base-spacing 0;

        .button,
        button {
            margin: 0 auto;
        }
    }
}

.tier {
    text-align: center;

    .tier-summary {
        position: relative;
        margin-right: $sm-spacing;
        margin-left: $sm-spacing;

        @include media($small) {
            margin-right: $base-spacing;
            margin-left: $base-spacing;
        }

        .tier-name {
            padding-top: $base-spacing;
        }

        .button {
            margin-top: $base-spacing;
        }
    }

    .tier-name {
        color: $color-dark-alpha;
        font-size: 24px;
        font-size: 1.5rem;
    }

    .tier-price {
        display: block;
        font-weight: $light;
        font-size: 34px;
        font-size: 2.125rem;

        em {
            color: $color-dark-gamma;
            font-style: normal;
            font-weight: normal;
            font-size: 20px;
            font-size: 1.25rem;
        }
    }

    .tier-heading {
        color: $color-dark-gamma;
        font-weight: normal;
        font-size: 16px;
        font-size: 1rem;
        line-height: 1.3;

        strong {
            color: $color-dark-alpha;
            font-weight: normal;
/*
            font-size: 18px;
            font-size: 1.125rem;
*/
        }

    }

    .tier-features {
        margin-top: $base-spacing;
    }

    .tier-feature {
        border-top: 1px solid $border-color;
        padding-top: $sm-spacing;
        margin-top: $sm-spacing;
        margin-right: $base-spacing;
        margin-left: $base-spacing;

        .tier-heading {
            margin-bottom: $sm-spacing;

            sub {
                display: block;
                margin-top: $tiny-spacing;
            }
        }

        p {
            margin-top: 0;
        }

        sub {
            color: $color-dark-gamma;
            font-style: italic;
        }

        svg.iconic {
            width: 0.8em;
            height: 0.8em;
            fill: $color-primary-delta;
        }

        ul {
            @extend .list-reset;

            li {
                display: inline-block;
                font-size: 16px;
                font-size: 1rem;

                svg.iconic {
                    width: .8125em;
                    height: .8125em;
                    margin-right: 4px;
                }
            }
        }
    }

    form {
        text-align: left;
        padding: 0;
    }

    .form-field {
        margin-right: $sm-spacing;
        margin-left: $sm-spacing;

        @include media($small) {
            margin-right: $base-spacing;
            margin-left: $base-spacing;
        }
    }

}


@include media($medium) {

    .tier:nth-of-type(1) .tier-feature-list{
        background-color: lighten( $off-white-2, 3% );
    }

    .tier:nth-of-type(2) .tier-feature-list{
        background-color: $off-white-2;
    }

    // CURRENTLY DISABLED

    // .tier:nth-of-type(3) .tier-feature-list{
    //     background-color: darken( $off-white-2, 3% );
    // }



    //.tier:nth-of-type(4) .tier-feature-list{
    //    background-color: darken( $off-white-2, 6% );
    //}

}

.plans-free {
    margin: $base-spacing auto;
    align-items: flex-start;

    @include media($small) {
        margin: $lg-spacing auto;
    }

    @include media($small) {
        .flex-item-2 {
            padding: $base-spacing 40px;
        }
    }
}

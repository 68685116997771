.masthead {
    position: relative;
    background-color: $color-primary;
    box-shadow: 0 0 4px rgba(0,0,0,0.12),
        0 4px 4px rgba(0,0,0,0.24);

    a {
        color: $color-tertiary;
    }

    svg.iconic {
        fill: $color-tertiary;
    }

    .masthead-flag {

        @include media($small) {
            float: left;
            margin: 0 $base-spacing;
        }

        .logo {
            position: relative;
            top: 2px;
            z-index: $body;
        }
    }

    .logo {
        width: 170px;
        height: 60px;
    }

    a.logo {

        &:hover {
            opacity: 0.8;
        }
    }

    .menu {

        @include media($small) {
            text-align: right;
            padding-left: 224px;
        }
    }

    &.fixed {

        .logo {

            @include media($small) {
                top: 6px;
                width: 40px;
                height: 35px;
                background-position: left center;
                background-size: cover;
            }
        }

        .menu {

            @include media($small) {
                margin-top: $tiny-spacing;
                margin-bottom: $tiny-spacing;
            }

            @include media($medium) {
                margin-top: 0;
                margin-bottom: 0;
            }
        }
    }
}

// SLIDE UP
@keyframes slideUp {
    from { top: 0px; }
    to   { top: -100px; }
}
@-moz-keyframes slideUp {
    from { top: 0px; }
    to   { top: -100px; }
}
@-webkit-keyframes slideUp {
    from { top: 0px; }
    to   { top: -100px; }
}
@-ms-keyframes slideUp {
    from { top: 0px; }
    to   { top: -100px; }
}
@-o-keyframes slideUp {
    from { top: 0px; }
    to   { top: -100px; }
}

// SLIDE DOWN
@keyframes slideDown {
    from { top: -100px; }
    to   { top: 0px; }
}
@-moz-keyframes slideDown {
    from { top: -100px; }
    to   { top: 0px; }
}
@-webkit-keyframes slideDown {
    from { top: -100px; }
    to   { top: 0px; }
}
@-ms-keyframes slideDown {
    from { top: -100px; }
    to   { top: 0px; }
}
@-o-keyframes slideDown {
    from { top: -100px; }
    to   { top: 0px; }
}

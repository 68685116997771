/* Foundation v2.1.4 http://foundation.zurb.com */
/* Artfully masterminded by ZURB  */
/* Even more artfully customized by Sophie Shepherd */

.table-wrapper {
	position: relative;
	overflow: hidden;
	border-right: 1px solid $color-light-beta;
    margin: $base-spacing auto;

	@include media($small) {
		width: 90%;
    	max-width: 1200px;
	}

	.scrollable {
		margin-left: 44%;
		overflow: scroll;
		overflow-y: hidden;

		@include media($mobile) {
			margin-left: 30%;
		}
	}
}

table.responsive {
	margin-bottom: 0;
	border-spacing: 0px;
	table-layout: fixed;
	width: 100%;

	td, th {
		padding: 12px 15px;
		text-align: left;
		position: relative;
		//white-space: nowrap;
		overflow: hidden;
		font-size: 12px;
		width: 130px;

		@include media($small) {
			width: 12%;
			font-size: 16px;
		}

	}

	th {
		font-weight: bold;
		font-size: 16px;
		padding: 10px 15px 10px;

		@include media($small) {
			padding: 20px 15px;
			border-left: 1px solid $color-light-beta;

			&:first-of-type {
				width: 30%;
				border-left: 0px solid $color-light-beta;
			}
		}
	}

	td {
		border-bottom: 1px solid $color-light-beta;
		border-right: 1px solid $color-light-beta;

		&:first-of-type {
			border-right: 0px solid $color-light-beta;

			@include media($small) {
				border-right: 1px solid $color-light-beta;
			}

		}

		&:last-of-type {
			border-right: 0px solid $color-light-beta;

		}

	}

	th:first-child,
	td:first-child,
	&.pinned td {
		display: none;

		@include media($small) {
			display: table-cell;
		}

	}

	tr.plan-select td {
		padding: 0;

		&:first-child {
			border-bottom: 0;
		}

        button,
        .button {
            margin: $sm-spacing;
        }
	}

	.row-group-header {

		td {
			border-right: 0;
			background: $color-tertiary;
		}

	}

}

.table-wrapper .pinned {
	position: absolute;
	left: 0;
	top: 0;
	width: 44%;
	min-width: 100px;
	overflow: hidden;
	overflow-x: scroll;
	color: $black;
    background-color: transparent;
    padding-top: 0;
    padding-left: 0;

	@include media($mobile) {
		width: 30%;
	}

	table {
		border-right: none;
        border-left: none;
        width: 100%;

		th, td {
			padding: 12px 10px;
			text-align: left;
			white-space: nowrap;
			font-size: 12px;
		}

		td {
			border-bottom: 1px solid transparent;
		}

		tr {
			height: auto;
			margin-right: 1px;
			border-right: 1px solid $color-light-beta;
		}

		.row-group-header {
			background: $color-tertiary;

			td {
				border-bottom: 1px solid transparent;
				font-weight: bold;
				color: $slate;
				text-transform: uppercase;
				letter-spacing: 1px;
				font-size: 12px;
				border-right: 1px solid $color-light-beta;
			}

		}

	}

}

.person {
    position: relative;
    min-height: 80px;
    text-align: center;
    padding-right: $sm-spacing;
    padding-left: $sm-spacing;
    margin-top: $base-spacing;
    margin-bottom: $base-spacing;

    @include media($mobile-up) {
        padding-right: $sm-spacing;
        padding-left: 96px;
        margin-top: $lg-spacing;
        margin-bottom: $lg-spacing;
    }

    .person-name {
        font-weight: normal;
        font-size: 20px;
        font-size: 1.25rem;
        line-height: 1.2;
        margin-bottom: 0;

        + p {
            color: $color-dark-gamma;
            font-size: 14px;
            font-size: .875rem;
            margin-top: 4px;
        }
    }

    .media, .bd { // this pattern from here http://www.stubbornella.org/content/2010/06/25/the-media-object-saves-hundreds-of-lines-of-code/
        overflow:hidden;
        _overflow:visible;
        zoom:1;
    }

    img {
        display: inline;
        width: 80px;
    }

    @include media($mobile) {
        text-align: left;

        .img {
            position: absolute;
            top: 0;
            left: 0;
            margin-right: $base-spacing;
        }

        img {
            display: block;
        }
    }

}

// Legacy Posts
.blog-post {

    &.main-content ol,
    &.main-content ul {
        font-size: 16px;
        margin: 20px;

        @include media($small) {
            font-size: 20px;
        }

    }

    article {
        line-height: 1.5;
        font-size: 16px;

        @include media($small) {
            font-size: 20px;
        }

    }

    p {
        font-size: 1em;

        @include media($small) {
            font-size: 1em;
        }

    }

}

// Intercom

#intercom-container [class*=header-] {
    background-attachment: inherit;
}

.benefits {

    .benefits-item {
        margin-bottom: $base-spacing;

        @include media($small) {
            margin-bottom: 0;
        }

        &:nth-of-type(even) {


            .benefits-item-illustration {

                @include media($small) {
                    position: relative;
                    left: 50%;
                }
            }

            .benefits-item-body {

                @include media($small) {
                    position: relative;
                    left: -50%;
                }
            }
        }
    }
}

// Front-End Login Form
.login-form {
    width: 90%;
    margin: 50px auto 30px;

    @include media($small) {
        width: 50%;
        margin: 150px auto 50px;
    }

    @include media($medium) {
        width: 40%;
    }

    @include media($large) {
        width: 18%;
    }

}

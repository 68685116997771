.filters {
    @include clearfix;

    &.init {
        display: none;

        &.active {
            display: block;
        }

        @include media($small) {
            display: block;
        }
    }

    .filters-heading {
        display: block;
        color: $color-dark-alpha;
        margin-top: $sm-spacing;
        margin-bottom: $tiny-spacing;

        @include media($medium) {
            float: left;
            margin-top: 10px;
        }
    }

    .filters-group {

        @include media($mobile-up) {
            float: left;
            width: 45%;
            margin-right: 5%;
        }

        @include media($medium) {
            margin-right: 0;

            &:first-child {
                width: 70%;
            }

            &:last-child {
                width: 30%;
            }
        }
    }

    .form-field {
        font-size: .875em;

        @include media($medium) {
            display: inline-block;
            margin: 0 $sm-spacing;
        }

        select {
            max-width: 150px;
        }
    }

    .flex-container-small {
        justify-content: space-between;
    }

}

.search-form-wrapper {
    flex: 0 0 50%;
    align-self: flex-start;

    @include media($medium) {
        flex: 0 0 35%;
        padding-top: 18px;
    }

    .search-form {
        display: block;
        padding: 0;

        input, button {
            display: inline;
            margin: 0;
            width: inherit;
        }

        input[type="search"] {
            @include radius (5px, 0px, 0px, 5px);
            height: 33px;
            width: 85%;
        }

        input[type="submit"], button[type="submit"] {
            padding: 9px 0 11px;
            margin-left: -3px;
            @include radius (0px, 5px, 5px, 0px);
            height: 33px;
            width: 15%;
            text-align: center;

            img {
                display: inline;
            }
        }

    }

}

.slide {

    &.init {
        overflow: hidden;
        @include transition(0.5s height ease-in-out);

        @include media($small-down) {
            height: auto !important;
        }

        @include media($small) {
            [data-slide] {
                display: inline-block;

                &.btn-back {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 33px;
                    height: 33px;
                    @include equal-radius(100%);
                    padding-right: 0;
                    padding-left: 0;
                }
            }
        }

        @include media($small) {

            .form-heading {
                position: relative;
                top: $tiny-spacing;
            }

            .form-field {
                position: relative;
            }

            .device-preview {
                display: block;
                position: absolute;
                top: -40px;
                right: 75px;
                width: 210px;
                height: 210px;

                + .form-field-description {
                    margin-top: 90px;
                }
            }
        }
    }

    .slide-frame {

        @include media($small) {
            @include clearfix;
            width: 200%;
        }
    }

    .slide-window {
        @include media($small-down) {
            margin-left: 0 !important;
        }

        @include media($small) {
            position: relative;
            float: left;
            padding-top: $sm-spacing;
            padding-left: 50px;
            @include transition(0.75s all ease-in-out);

            &:first-child {
                padding-left: 0;
            }
        }
    }

    .btn-slide,
    .device-preview {
        display: none;

        svg.iconic {
            display: inline-block;
            position: relative;
            top: 2px;
            width: 1em;
            height: 1em;
            margin: 0 auto;
        }
    }

    &.slide-2 {

        .slide-frame {

            @include media($small) {
                width: 200%;
            }
        }

        .slide-window {

            @include media($small) {
                width: 50%;
            }
        }
    }

    &.slide-3 {

        .slide-frame {

            @include media($small) {
                width: 300%;
            }
        }

        .slide-window {

            @include media($small) {
                width: 33.3333333%;
            }
        }
    }

    &.slide-4 {

        .slide-frame {

            @include media($small) {
                width: 400%;
            }
        }

        .slide-window {

            @include media($small) {
                width: 25%;
            }
        }
    }
}

.device-preview {
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url('../img/devices-canvas.png');

    .device-title {
        display: block;
        color: $white;
        overflow: hidden;
        font-weight: $bold;
        text-align: center;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-shadow: 0px 1px 1px rgba(0,0,0,.5);

        &.large {
            max-height: 33px;
            font-size: 10px;
            padding: 5% 15% 0;
        }

        &.small {
            font-size: 3px;
            padding-top: 13%;
            padding-left: 78%;
        }
    }
}

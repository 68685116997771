.ecosystem { //styles specific to the ecosystem section on the about page

    .main-paragraph {
        padding-right: $base-spacing;

        @include media($large) {
            width: 80%;
            margin: 0 auto $sm-spacing;
        }

    }

    .ecosystem-logos img {
        padding: $sm-spacing $base-spacing;

        @include media($medium) {
            padding: 0 0 0 $base-spacing;
        }
    }

    .divider {
        margin: $lg-spacing 0;
    }

    img {
       // mix-blend-mode: multiply;
    }

}

.initiatives {
    text-align: center;

    img {
        display: inline-block;
        max-width: 200px;
    }

    .flex-container {
         align-items: flex-start;
    }

    .flex-item-2:first-of-type {
        @include media($small) {
            padding: 0 $base-spacing 0 0;
        }
    }

    .flex-item-2:last-of-type {
        @include media($small) {
            padding: 0 0 0 $base-spacing;
        }
    }

    @include media($small) {
        text-align: left;
    }

    @include media($large) {
        img {
            float: left;
            display: block;
            max-width: 150px;
            margin-right: $base-spacing;
            margin-top: $lg-spacing;
        }

        .media, .bd { // this pattern from here http://www.stubbornella.org/content/2010/06/25/the-media-object-saves-hundreds-of-lines-of-code/
            overflow:hidden;
            _overflow:visible;
            zoom:1;
        }

    }

}

.employee-list {
    .flex-item-4 {
        align-self: flex-start;
    }
}
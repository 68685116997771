/*------------------------------------*\
$LAYOUT -- Move to own file?!?!?!
\*------------------------------------*/

.wrapper {
    max-width: $max-width;
    margin: 0 $sm-spacing;

    @include media($small) {
        margin: 0 $base-spacing;
    }

    @include media($medium) {
        margin: 0 auto;
    }
}

.ten-col-wrapper {
    @include clearfix;
    width: 90%;
    max-width: 1200px;
    margin: 0 auto;
}

.eight-col-wrapper {
    width: 90%;
    margin: 0 auto;
    max-width: ($max-width/1.5);
}

.six-col-wrapper {
    width: 90%;
    margin: 0 auto;
    max-width: ($max-width/2);
}

.flex-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.flex-container-mobile {
    @include media($mobile) {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    }
}

.flex-container-small {
    @include media($small) {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    }
}

.flex-container-medium {
    @include media($medium) {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    }
}

.flex-item-2 {
    flex: 0 0 50%;
    min-width: 0;
}

.flex-item-4 {
    flex: 0 0 50%;
    min-width: 0;

    @include media($medium) {
        flex: 0 0 25%;
    }
}

.flex-item-body {
    padding-top: $base-spacing;
    padding-bottom: $base-spacing;

    @include media($small) {
        padding-right: $base-spacing;
        padding-left: $base-spacing;
    }
}

.container {
	clear:  both;
    max-width: 1200px;
    margin: 0 auto;
    @include outer-container;
}

.container-centered {
	@extend .container;
	text-align: center;
}

.hide-when-small {
    display: none;

    @include media($small) {
        display: inline;
    }
}

.hidden {
    display: none !important;
}

.clearfix {
    clear: both;
}

.fade-out {
    opacity: 0 !important;
}

.fade-in {
    opacity: 1 !important;
}

.absolute {
    position: absolute;
}

.visible {
    display: block;
}

.inline {
    display: inline;
}

.centered {
    text-align: center;
}

.green {
    color: #339933;
}

.red {
    color: #B80000;
}

.multiply {
    mix-blend-mode: multiply;
}

.ratio-16_9 {
	position: relative;
	padding-bottom: 56.25%; /* 16:9 */
	padding-top: 25px;
	height: 0;

    iframe,
    > div,
    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    [class*="cols-"] & {

        @include media($small) {
            margin-left: $lg-spacing;
        }
    }
}

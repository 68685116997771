.dropdown-menu {
    @include containerMenu;
   position: absolute;
   min-width: 160px;
   z-index: $dropdown;
   margin: 0;
   @include transition(0.3s);

   &.init {
      display: none;

      &.active {
         display: block;
         clear: both;
      }

      &[data-toggle-animate] {
          display: none;
          opacity: 0;
      }
   }

   .dropdown-menu-title {
       @include typeBeta;
       padding-bottom: $tiny-spacing;
       margin: $sm-spacing;
   }

   .dropdown-menu-body {
       overflow-y: scroll;
   }

   ul {
       @extend .list-reset;
   }

   li {
       position: relative;
       list-style-type: none;
       margin: 0;

       &:first-child {
           margin-top: $tiny-spacing;
       }

       &:last-child {
           margin-bottom: $tiny-spacing;
       }

       svg.iconic {
           position: absolute;
           left: $sm-spacing;

           + .label {
               display: inline-block;
               padding-left: $sm-spacing+12;
           }
       }

       a {
            display: block;
            padding: $tiny-spacing $sm-spacing;

            &:hover {
                color: $white;
                background-color: $color-primary;

                svg.iconic {
                    fill: $white;
                }
            }
       }
   }

   dl {

       dt.list-item {

           &:first-child {
               margin-top: $sm-spacing;
           }
       }
   }

   dt.list-item {
       @include typeBase;
       padding: 0;
       margin: $base-spacing $sm-spacing;

       + dd {
           font-size: .785714286em;
           border-bottom: none;
           padding-bottom: 0;
           margin-top: -$sm-spacing;
           margin-bottom: $sm-spacing;
       }
   }

   dd {
       margin-right: $sm-spacing;
       margin-left: $sm-spacing;
   }

   .form-field,
   fieldset {
      margin: $tiny-spacing $sm-spacing !important;

      &:first-child {
          margin-top: $sm-spacing !important;
      }

      &:last-child {
          margin-bottom: $sm-spacing !important;
      }

      .form-field {
          margin: 0 auto $tiny-spacing !important;
      }
   }

   .divider {
       margin: $tiny-spacing 0;
   }
}

.dropdown-trigger {

   &.init {
      position: relative;
      padding: $tiny-spacing $sm-spacing;

      &:hover {
          cursor: pointer;
      }

      svg.chevron {
          width: 10px;
          height: 10px;
          fill: $color-light-alpha;
          margin-right: 0;
          margin-left: $tiny-spacing;
          @include transition(0.3s);
      }

      &.active {

          svg.chevron {
              @include transform(rotate(180deg));
          }
      }
   }
}

[data-toggle] {

    &:hover {
        cursor: pointer;
    }
}

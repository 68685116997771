/*------------------------------------*\
    $FORMS
\*------------------------------------*/

form {
    @include equal-radius;
/*
    padding: $sm-spacing;

    @include media($small) {
        padding-right: $base-spacing;
        padding-left: $base-spacing;
    }
*/
}

.form-heading {
    font-weight: $bold;
    font-size: 16px;
    font-size: 1.142857143rem;
    margin-top: 0;
    margin-bottom: $sm-spacing;
}

.form-group {
    margin-bottom: $base-spacing;
}

label,
.form-label {

}

fieldset {
    border: none;

    legend {
        margin-bottom: $sm-spacing;
    }
}

input[type="text"],
input[type="email"],
input[type="password"],
input[type="search"],
input[type="file"],
input[type="tel"],
input[type="url"],
textarea {
    display: block;
    width: 100%;
    max-width: 450px;
    max-height: 34px;
    color: $text-color;
    @include typeBase;
    font-family: $base-font-family;
    line-height: 1.4;
    @include text-align(left);
    outline-color: $white;
    border: $base-border-dark;
    @include equal-radius;
    padding: $tiny-spacing $sm-spacing;
    @include transition;
    @include box-shadow($top: 1px, $left: 0, $blur: 1px, $spread: 0, $color: rgba(0,0,0,.1), $inset: true);

    &:focus {
        border: 1px solid $blue;
    }
}

input[type="tel"] {
    display: inline-block;
    width: auto;
}

textarea {
    max-height: none;
}

input[type="checkbox"],
input[type="radio"] {
    display: inline-block;
    width: auto;
    margin: $tiny-spacing $tiny-spacing $tiny-spacing 0;
}

button[type="submit"], input[type="submit"] {
    margin: 0;
}

::-webkit-input-placeholder {
    padding-top: 3px;
    font-size: em(13);
    font-family: $base-font-family;
}

:-moz-placeholder { /* Firefox 18- */
    padding-top: 3px;
    font-size: em(13);
    font-family: $base-font-family;
}

::-moz-placeholder {  /* Firefox 19+ */
    padding-top: 3px;
    font-size: em(13);
    font-family: $base-font-family;
}

:-ms-input-placeholder {
    padding-top: 3px;
    font-size: em(13);
    font-family: $base-font-family;
}

textarea {
    width: 100%;
    height: 120px;
}

select {
    @include direction;
    font-size: $base-font-size;
    font-family: $base-font-family;
    line-height: 1.4;
    @include equal-radius;
    border: $base-border-dark;
    background-color: $color-light-delta;
    @include lrswap(padding, $tiny-spacing 26px $tiny-spacing $tiny-spacing);
}


.custom-select {
    display: inline-block;
    position: relative;

    &:after {
        content: '';
        position: absolute;
        top: 50%;
        right: 10px;
        width: 7px;
        height: 7px;
        background: transparent;
    	border-top: 2.5px solid $color-light-alpha;
    	border-right: 2.5px solid $color-light-alpha;
        margin-top: -6px;
        @include transform(rotate(135deg));
    }

    select {
        -webkit-appearance: none;
        -moz-appearance: none;
    }
}

legend {
    @include typeBase;
    color: $black;

    &.form-label {
        padding-bottom: 0;
    }
}

.form-field {
    margin-bottom: $base-spacing;

    p {
        font-size: em(15);
    }
}

.checkbox label {
    text-transform: none;
    font-weight: $body-font-weight;
    font-size: em(16);
    letter-spacing: 0;
}

label + .checkbox {
    margin-top: $sm-spacing;
}

.input-with-button {
    text-align: center;
    max-width: 700px;
    margin: 0 auto;

    button,
    [type="submit"] {
        margin-top: $sm-spacing;
    }

    @include media($medium) {
    margin: 0 auto $sm-spacing;
       padding: 5px 0;

        input[type="text"],
        input[type="email"] {
            display: inline-block;
            width: 50%;
            border-top-right-radius: 0px;
            border-bottom-right-radius: 0px;
            margin: 1px -5px 0 0;
            height: 36px;
        }

        button,
        [type="submit"] {
            display: inline-block;
            width: 50%;
            border-top-left-radius: 0px;
            border-bottom-left-radius: 0px;
            margin: 0;
            height: 37px;
        }

    }

    @include media($large) {

        input[type="text"],
        input[type="email"] {
            width: 58%;
        }

        button,
        [type="submit"] {
            width: 42%;
        }

    }

}

.input-with-append {

    label {
        display: block;
    }

    input[type="text"],
    input[type="email"],
    input[type="url"] {
        display: inline-block;
        max-width: 50%;
        font-style: italic;
        margin-right: $tiny-spacing;
    }

    .append {
        font-style: italic;
        font-size: 1.15em;
    }
}

.headline-inline-with-input {
    @include media($small) {
        width: 100%;
        margin: $base-spacing auto 0;
        text-align: center;

        h3 {
            //display: inline-block;
            //margin-right: $base-spacing;
        }

        .input-with-button {
            display: inline-block;
            width: 100%;
        }

        input {
            border-top-right-radius: 0px;
            border-bottom-right-radius: 0px;
            width: 50%;
            float: left;
            height: 35px;
        }

        button {
           width: 50%;
           float: right;
           border-top-left-radius: 0px;
           border-bottom-left-radius: 0px;
            height: 35px;
        }

    }

    @include media($medium) {
        h3 {
            display: inline-block;
            margin-right: $base-spacing;
            margin-bottom: 30px;
        }

        .input-with-button {
            margin-bottom: -13px;
        }
    }
}

form.full-width {
    border-radius: 0;
}

.tgl{//http://codepen.io/mallendeo/pen/eLIiG
    display: none !important;
    // add default box-sizing for this scope
    &, &:after, &:before,
    & *, & *:after, & *:before,
    & + .tgl-btn {
        box-sizing: border-box;

        &::selection{
            background: none;
        }

    }

    + .tgl-btn {
        @include direction;
        outline: 0;
        display: inline-block;
        width: 55px;
        height: 30px;
        position: relative;
        cursor: pointer;
        user-select: none;
        background: #ccc;
        border-radius: 2em;
        padding: 3px;
        transition: all .4s ease;

        &:after, &:before {
            position: relative;
            display: block;
            content: "";
            width: 50%;
            height: 100%;
        }

        &:after {
            left: 0;
            border-radius: 50%;
            background: white;
            transition: all .2s ease;
        }

        &:before {
            display: none;
        }

    }

    &:checked{

        + .tgl-btn {
            background: green;

            &:after {
                @include left(50%);
                background: white;
            }

        }

    }

}

/*------------------------------------*\
    $TYPOGRAPHY
\*------------------------------------*/

p {
    line-height: 1.357142857;
    margin: $sm-spacing 0;
}

/* inline */
em {
    font-style: italic;
}

strong {
    font-weight: 900;
}

.caps {
    color: $color-dark-delta;
    font-weight: 900;
    font-size: 10px;
    font-size: .714285714rem;
    text-transform: uppercase;
    letter-spacing: 1px;
}

a {
    color: $link-color;
    text-decoration: none;
    @include transition;

    &:hover,
    &:focus {
        cursor: pointer;
        color: $link-hover-color;
    }
}

p,
li {

    a {
        border-bottom: 1px dotted $link-color;

        &:hover,
        &:focus {
            border-bottom: 1px solid $link-hover-color;
        }
    }
}

.section-title {
    color: $color-dark-alpha;
    font-weight: $light;
    font-size: 30px;
    font-size: 1.875rem;
    margin-bottom: $base-spacing;

    @include media($small) {
        font-size: 40px;
        font-size: 2.5rem;
    }
}

.section-heading {
    color: $color-dark-alpha;
    font-weight: $bold;
    font-size: 24px;
    font-size: 1.5rem;

    @include media($medium) {
        font-size: 34px;
        font-size: 2.125rem;
    }
}

img {
    display: block;
    max-width: 100%;

    a & {
        border: none;
    }
}

caption { }


code {
    font: 12px/1 'Courier New', Courier, monospace;
}

sub, sup {
    line-height: 0;
}

// Lists
ol, ul {
    margin: $sm-spacing;

    li {
        line-height: 1.357142857;
        margin: $sm-spacing $sm-spacing $sm-spacing auto;
    }

}

ul {

    li {
        list-style: disc;
    }

}

.list-reset {
    margin: 0;

    li {
        list-style-type: none;
        margin: 0;
    }
}

blockquote {
	@include border-left(4px solid $color-secondary);
    @include padding-right($sm-spacing);
    @include padding-left($sm-spacing);
	font-style: italic;
    margin: $base-spacing 0;

    footer {
        font-style: normal;
        padding-bottom: $sm-spacing;
    }
}

table {
    border-collapse: collapse;
}

th { }

td { }

.form-field {

    .form-field-description {
        opacity: .5;
    }

    .form-field-feedback {
        font-weight: 900;
    }

    &.input-with-label {
        .form-field-description,
        .form-field-feedback {

            @include media($small) {
                margin-top: $base-spacing;
            }
        }
    }

    &.focus {

        .form-field-description {
            opacity: 1;
            @include transition(0.25s all ease-in-out);

            @include media($small) {
                @include scale(1.05);
            }
        }
    }

    &.error {

        input[type="text"],
        input[type="tel"],
        input[type="url"],
        input[type="password"] {
            border: 1px solid $red;
        }

        button[type="submit"] {

        }

        .form-field-feedback {
            color: $red;
        }
    }

    &.success {

        input[type="text"],
        input[type="tel"],
        input[type="url"],
        input[type="password"] {
            border: 1px solid $green;
        }

        .form-field-feedback {
            color: $green;
        }
    }
}

.form-field,
fieldset {
   @include clearfix;
   position: relative;
   margin-bottom: $base-spacing;

   label {
      display: block;
      margin-bottom: $sm-spacing;

      + p {
         font-style: italic;
         margin: $tiny-spacing 0;
      }

      input[type="text"],
      input[type="email"],
      input[type="tel"],
      textarea,
      .custom-select,
      select {
          margin-top: $tiny-spacing;
      }

      .tooltip {
          display: inline-block;
          color: $white;
          width: 1.142857143em;
          height: 1.142857143em;
          font-weight: $bold;
          font-size: .857142857em;
          text-align: center;
          @include equal-radius(100%);
          background-color: $color-primary;
          padding: 2px;
          margin: 0 4px;

          svg.iconic {
              width: 10px;
              height: 10px;
              fill: $white;
          }
      }
   }

   button,
   .button {
       margin-right: $tiny-spacing;
       margin-bottom: $tiny-spacing;
   }

   figure {

       button,
       .button {
           margin-top: $tiny-spacing;
       }
   }

   .alert {
      margin: $tiny-spacing 0;
   }

   // REQUIRED FIELD
   &.required {

      label {

         &:after {
             content: '\2217';
             color: $red;
             font-weight: normal;
             font-size: 1.75em;
             @include margin-left($tiny-spacing);
         }
      }
   }

   // INVALID FORM FIELD/FIELDSET VALUE
   &.error {

      label,
      legend,
      p {
         color: $error-color;
      }

      input,
      input[type="text"],
      input[type="email"],
      input[type="password"],
      input[type="search"],
      textarea,
      select {
         border-color: $error-color;
         box-shadow: 0 0 $tiny-spacing $error-color;

         &:focus {
            border-color: $error-color;
         }
      }

      .form-field {

          label {
              color: $error-color;
          }
      }

   }

   // VALID FORM FIELD VALUE
   &.success {

      input,
      input[type="text"],
      input[type="email"],
      input[type="password"],
      input[type="search"],
      textarea,
      select {
         border-color: $confirmation-color;

         &:focus {
            border-color: $confirmation-color;
         }
      }
   }

   // CHECKBOX OR RADIO BUTTON
   &.checkbox,
   &.radio {
       position: relative;
       @include padding-left(14px + $tiny-spacing);

      label {
         color: $text-color;
         @include typeBase;
         text-transform: none;
         letter-spacing: 0;
      }

      input[type="checkbox"],
      input[type="radio"] {
          position: absolute;
          top: 0;
          @include left(0);
          margin-top: 2px;
      }
   }

   // TITLE
   &.title {

       label {
           display: none;
       }

      input[type="text"] {
         max-width: none;
         @include typeBeta;
         padding: $sm-spacing;
      }
   }

   // SEARCH
   &.search {
       display: inline-block;

      label {
         display: none;
      }

      button,
      .button {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          margin: 0;
      }
   }

   &.location {

       input[type="text"] {

           + button,
           + .button {
               margin-top: $tiny-spacing;
           }
       }

       .map {
           height: 400px;
           border: 1px solid $color-light-beta;
           margin-bottom: $tiny-spacing;

           + .form-field {
               position: absolute;
               z-index: $body;
               top: $base-spacing;
               right: $tiny-spacing;
               left: $tiny-spacing;

               input[type="text"] {
                   display: inline-block;
                   width: 60%;

                   + button,
                   + .button {
                       margin-left: 4px;
                   }
               }

           }
       }
   }

   &.select {

      label {

         @include media($small) {
             display: inline-block;

             .custom-select {
                margin-right: $tiny-spacing;
             }

             + .custom-select {
                 margin-left: $tiny-spacing;
             }
         }
      }

      .custom-select,
      select {
          display: inline-block;
          margin-top: 0;
      }
   }

   &.url {

       input[type="text"],
       input[type="url"] {
           display: inline-block;
           width: auto;

           + .domain {
               margin-left: $tiny-spacing;
           }
       }
   }

   &.date {

       svg.iconic {
           position: absolute;
           top: 11px;
           @include left(16px);
           fill: $color-primary;
       }

       input[type="date"],
       input[type="datetime"] {
          @include padding-left($lg-spacing);
       }

       input[type="date"] {
           padding-right: 4px;
       }

       .date-joiner {
           display: inline-block;
           @extend .caps;
           text-align: center;
           @include equal-radius();
           border: 1px solid $color-light-beta;
           background-color: $color-light-delta;
           padding: 4px;
           margin-top: $tiny-spacing;
           margin-bottom: $tiny-spacing;
           margin-left: $tiny-spacing;
       }
   }

    &.input-and-button {

        input,
        input[type="text"],
        input[type="email"],
        input[type="password"],
        input[type="search"],
        input[type="url"] {
            width: auto;
            margin-bottom: $tiny-spacing;

            @include media($small) {
                display: inline-block;
                max-width: 200px;
                margin-right: $tiny-spacing;
                margin-bottom: 0;
            }
        }

        button,
        .button {
            position: relative;
            top: -1px;
            margin-bottom: 0;
        }
    }

   // TOGGLE SWITCH
   &.switch {
       position: relative;

      .toggle-switch {
          position: absolute;
          top: 0;
          right: 0;

         label {
            margin: 0;
         }
      }

      > label {
          display: inline-block;
          color: $text-color;
          @include typeBase;
          text-transform: none;
          letter-spacing: 0;
          padding-right: 72px;
      }
   }
}

fieldset {

    .custom-select {
        margin-top: -6px;
    }
}

fieldset,
.form-fieldgroup {
    position: relative;

    .overflow {
        display: none;
    }

    .form-fieldgroup {
        border-top: 1px solid $color-light-beta;
        padding-top: $tiny-spacing;
        margin-top: $tiny-spacing;
    }

    .form-field-toggle {
        display: none;
        color: $color-primary;
        padding: $tiny-spacing;

        &:hover {
            cursor: pointer;
            color: $color-primary-alpha;
        }

        svg.iconic {
            width: 8px;
            height: 8px;
            fill: $color-primary;
            margin-right: $tiny-spacing;
            @include transition(0.3s);
        }
    }

    &.has-overflow {

        .form-field-toggle {
            display: inline-block;
        }
    }

    &.show-overflow {

        .overflow {
            display: block;
        }

        .form-field-toggle {

            svg.iconic {
                @include transform(rotate(180deg));
            }
        }
    }
}

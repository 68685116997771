.side-nav-layout {
    align-items: stretch;

    .side-navigation {
        background: $off-white-2;
        padding: $base-spacing 30px $base-spacing;
        -webkit-transition: all .5s;
        -moz-transition: all .5s;
        -ms-transition: all .5s; 
        -o-transition: all .5s;
        transition: all .5s;
        width: 80%;
        position: absolute;
        top: $lg-spacing;
        left: -100%;
        margin-left: -40%;
        z-index: 900;

        @include media($mobile) {
            width: 55%;
        }

        @include media($small) {
            flex: 0 0 30%; 
            position: relative;
            display: block;
            margin-left: 0;
            left: 0;
            top: 0;
        }

        @include media($medium) {
            flex: 0 0 25%;
            padding: $lg-spacing;
            max-width: 350px;
        }

        &.on-screen {
            z-index: 300;
            left: 50%;
            margin-left: -40%;
            padding-top: $lg-spacing;
            
            @include media($mobile) {
                margin-left: -25%; 
            }

            @include media($small) {
                margin-left: 0;
                left: 0;
                top: 0;
                position: relative;
            }
        }

        .search-form-wrapper {
            margin-bottom: $base-spacing;

            input[type="search"] {
                width: 70%;
            }

            input[type="submit"], button[type="submit"] {
                width: 30%;
                text-align: center;

                img {
                    display: inline;
                }
            }
        }
        
        h3 {
            @extend h4;
            font-size: 14px; 
            //margin-bottom: 15px;
        }

        ul li {
            padding: 8px 0;
            font-size: 15px;
        }

        li.active {
            border-left: 3px solid $gold;
            padding-left: 10px; 

            a {
                color: $black;
            }
        }

    }

    .menu-trigger { 

        &:hover {
            cursor: pointer;
        }
        
        span {
            display: block;
        }

        &.close {
            position: absolute;
            font-weight: $bold;
            display: inline-block;
            right: $sm-spacing;
            top: $sm-spacing;

            img {
                display: inline;
                width: 20px;
                margin-bottom: -5px;
                margin-left: 5px;
            }

             @include media($small) {
                display: none;
            }
        }

        @include media($small) {
            display: none;
        }
    }

    .main-content {
        flex: 0 0 60%;
        margin-bottom: $lg-spacing;
        position: relative;

        @include media($small) {
            margin-top: $lg-spacing;
        }

        @include media($medium) {
            //flex: 0 0 50%;
        }

         &.menu-on-top {
            .blackout {
                position: fixed;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                width: auto;
                height: auto;
                overflow: hidden;
                background: rgba(0, 0, 0, 0.75);
                z-index: 100; //variable defined in settings.scss
                opacity: 1; 

                @include media($small) {
                    display: none;
                }
            }
         }
    }

    .search-results {
        h3 {
            margin-top: $base-spacing;
            margin-bottom: 0;

            @include media($small) {
                margin-top: $lg-spacing;
            }
        }

        p.small {
            margin: 0;
        }

        .pagination {
            margin-top: $lg-spacing;
        }
    }

    .offset-header:before {
       content: "";
       display: block;
       height: 60px;
       margin: -40px 0 0;
    }

}

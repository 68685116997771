.results {

    .page-headline {
        margin-bottom: $tiny-spacing;

        + p {
            margin-top: 0;
        }
    }
}

.social-links {
    margin-right: auto;
    margin-left: auto;

    li {
        display: inline;

        a {
            border-bottom-color: transparent;
        }

        svg {
            display: inline;
            max-width: 30px;
            padding-right: $tiny-spacing;

            path {
                fill: $link-color;
            }
        }

    }
}

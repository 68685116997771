.admin-edit-button-wrapper {
    text-align: center;

    .admin-edit-button {
        display: inline-block;
        border: none !important;
        border-radius: 3px;
        line-height: 18px;
        text-shadow: none;
        cursor: pointer;
        -webkit-appearance: none;
        -moz-appearance: none;

        transition: none;

        width: auto;
        margin: 0 auto 20px;
        padding: 5px 10px;
        color: #fff !important;
        font-weight: bold;
        background: hsl(8, 67%, 57%);

        &:active,
        &.active {
            background: #8c3b2e;
        }

    }

    &.left {
        text-align: left;
    }

}

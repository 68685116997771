/*------------------------------------*\
    $IMAGES
\*------------------------------------*/

.circular {
    border-radius: 50%;
    max-width: 100%;
}

.photograph {
    border: 5px solid $white;
    -webkit-box-shadow: 5px 5px 10px 0px rgba(0,0,0,0.1);
    -moz-box-shadow: 5px 5px 10px 0px rgba(0,0,0,0.1);
    box-shadow: 5px 5px 10px 0px rgba(0,0,0,0.1);
    transform: rotate(-3deg);

    @include media($small) {
        border: 15px solid $white;
    }
}

.logo {
    display: block;
    text-indent: -9999px;
    background-repeat: no-repeat;
    background-position: left center;
    background-size: contain;
    background-image: url('../img/ushahidi-wordmark.svg');
    margin: 0 auto;
    @include transition(0.3s);

    &:hover {
        opacity: 0.75;
    }
}

.device-ipad {
    position: relative;
    overflow: hidden;
    max-height: 250px;
    @include equal-radius(20px);
    border: 10px solid $black;
    border-right-width: 30px;
    border-left-width: 30px;

    &:before {
        z-index: 1;
        position:absolute;
        content:"";
        height:200%;
        width:100%;
        background-color: rgba(255,255,255,0.2);
        transform:rotateZ(-15deg);
        top:-50%;
        left:75%;
    }

    a {
        display: block;
        position: relative;
        z-index: 2;
    }
}

.activity-timeline {
    @include border-left(5px solid $lt-gray);
    @include padding-left($base-spacing);
    margin: $sm-spacing 0 $base-spacing;

    .timestamp {
        color: $black;

        &:before {
            content: "";
            position: absolute;
            top: 8px;
            @include left(-32px);
            width: 15px;
            height: 15px;
            border-radius: 20px;
            background: $highlight;
            //border: 5px solid $lt-gray;
        }
    }

    .activity-listing {

        &:before {
            content: "";
            position: absolute;
            top: 11px;
            @include left(-32px);
            width: 15px;
            height: 15px;
            border-radius: 20px;
            background: $white;
            border: 3px solid $lt-gray;
        }
    }

/*
    &.justnow {

        .activity-listing,
        .timestamp {
            opacity: .65;
        }

        #splash-message {

            .activity-listing,
            .timestamp {
                opacity: 1;
            }
        }
    }
*/
}

.body {
    @include clearfix;
    margin: $lg-spacing auto;

    .photograph {
        margin: 0 auto $lg-spacing;

        @include media($mobile-up) {
            max-width: 450px;
        }

        @include media($small) {
            float: left;
            max-width: 400px;
            margin-right: $lg-spacing;
        }
    }
}

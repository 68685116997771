@mixin clearfix {

    &:after {
        content: ".";
        display: block;
        height: 0;
        clear: both;
        visibility: hidden;
        font-size: 0;
    }

    .lt-ie8 & {
        zoom: 1;
    }
    
}

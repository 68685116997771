.postcard {
    position: relative;
    @include containerCard;
    @include clearfix;

    .post-band {
        @include radius(2px, 0, 0, 2px);
    }

    .listing-item-select {
        position: absolute;
        top: $sm-spacing;
        left: $sm-spacing;

        + .postcard-body {
            padding-left: $huge-spacing;
        }

    }

    .postcard-body {
        padding: $sm-spacing;

        @include media($small) {
            padding: $base-spacing;
        }

        .offset-header {
            margin-bottom: $base-spacing;
        }
    }

    .postcard-actions-trigger {
        display: inline-block;
        position: absolute;
        top: $sm-spacing;
        right: $sm-spacing;
        z-index: $body;
        width: $sm-spacing;
        height: $sm-spacing;
        font-size: 12px;
        text-align: center;
        @include equal-radius(100%);
        background-color: $color-light-beta;

        &:hover {
            cursor: pointer;
        }

        svg.iconic {
            fill: $white;
            margin-top: 4px;
            @include transition(0.3s);
        }

        &.active {
            z-index: $dropdown+1;
            font-weight: $bold;
            background-color: $color-dark-beta;

            svg.iconic {
                margin-top: 2px;
                @include transform(rotate(180deg));
            }
        }

    }

    .dropdown-menu {
        top: 4px !important;
        right: 4px !important;
        left: auto !important;
        padding-top: $lg-spacing;
    }

    .postcard-title {
        position: relative;
        color: $color-dark-alpha;
        @include typeGamma;
        margin-right: $lg-spacing;
        margin-bottom: .625em;

        @include media($small) {
            font-size: 20px;
            font-size: 1.25rem;
        }

        .post-band {
            left: -$sm-spacing;
        }
    }

    .postcard-image {
        position: absolute;
        top: $sm-spacing;
        left: $sm-spacing;
        width: $huge-spacing;

        + .postcard-title {
            padding-left: 56px;
            margin-right: 0;

            + .metadata {
                padding-left: 56px;
            }
        }
    }

    .metadata {
        margin-right: $lg-spacing;
        margin-bottom: $sm-spacing;

        a.metadata-author {
            color: $color-dark-alpha;
        }
    }

    .postcard-title,
    .metadata-author {

        .avatar {
            width: 20px;
            height: 20px;

            &:hover {
                border-color: rgba(0,0,0,.24);
            }
        }
    }

    .postcard-field {
        margin: $sm-spacing 0;
        margin-right: $lg-spacing;

        &:last-child {
            margin-bottom: 0;
        }

        .form-label {
            padding-top: $base-spacing;
        }

        p {
            max-width: 700px;

            &:last-child {
                margin-bottom: 0;
            }
        }

        img.postcard-image {
            position: static;
            max-width: 100%;
            width: auto;
            border: 1px solid rgba(0,0,0,.1);
            box-shadow: 0 1px 1px rgba(0,0,0,.1);

            @include media($medium) {
                max-width: 400px;
            }
        }

        .map {
            width: 100%;
            max-width: 700px;
            height: 300px;
            border: 1px solid $color-light-beta;
        }
    }

    .postcard-hero {
        padding: $sm-spacing;
        padding-bottom: 0;

        @include media($small) {
            float: left;
            width: 25%;
            padding: 0;
            margin: $sm-spacing;

            img.postcard-image {
                max-width: 100%;
            }

            + .postcard-body {
                float: left;
                width: 70%;
                padding-left: 0 !important;
            }
        }

        @include media($medium) {
            width: 33.333333%;
            margin: $base-spacing;

            + .postcard-body {
                width: 60%;
            }
        }

        .postcard-image {
            position: static;
            width: auto;
        }
    }

    .tabs-menu {
        margin-right: -$sm-spacing;
        margin-left: -$sm-spacing;
    }

    .page-anchors {
        margin: $sm-spacing 0;
    }

    .highlight {
        font-weight: $bold;
        border-bottom: 2px solid $color-secondary;
    }

    &[role="article"] {
        @include containerSheet;

        .postcard-title {
            font-size: 20px;
            font-size: 1.428571429rem;
            padding-right: $huge-spacing+$tiny-spacing;
            margin-bottom: $sm-spacing;

            @include media($medium) {
                font-size: 24px;
                font-size: 1.714285714rem;
                margin-top: $tiny-spacing;
                margin-bottom: $base-spacing;

                .post-band {
                    left: -$base-spacing;
                }
            }
        }

        .postcard-body {
            padding: $sm-spacing;

            @include media($medium) {
                padding: $sm-spacing $base-spacing;
            }

            p {
                max-width: 700px;

                @include media($medium) {
                    max-width: 80%;
                }
            }

            h2,
            h3,
            h4 {
                color: $color-primary-beta;
            }

            hr {
                margin: $base-spacing auto;
            }

            figure {
                max-width: 88%;
                margin: $sm-spacing auto;

                figcaption {
                    font-style: italic;
                    text-align: center;
                    margin-top: $tiny-spacing;
                }
            }

            .align-right {

                @include media($medium) {
                    float: right;
                    max-width: 50%;
                    margin-top: $base-spacing;
                    margin-bottom: $base-spacing;
                    margin-left: $base-spacing;
                }
            }
        }

        .postcard-field {

            .form-label {
                border-top: $base-border;
            }

            &:first-child {

                .form-label {
                    border-top: none;
                }
            }
        }

        .postcard-actions-trigger {
            background-color: $color-primary;

            @include media($medium) {
                top: $lg-spacing;
                right: $base-spacing;
            }
        }

        .tabs-menu {

            @include media($medium) {
                margin-right: -$base-spacing;
                margin-left: -$base-spacing;
            }
        }
    }

    &.featured {
        @include containerSheet;

        .postcard-title {

            @include media($medium) {
                font-size: 20px;
                font-size: 1.428571429rem;
                margin-bottom: $sm-spacing;
            }
        }

        .postcard-hero {

            + .postcard-body {

                .postcard-field {

                    p {

                        @include media($medium) {
                            max-width: none;
                        }
                    }
                }
            }
        }

    }
}

.breadcrumbs {
    @extend .list-reset;
    font-size: 14px;

    span {
        font-size: 20px;
        color: $color-tertiary;
        padding-right: 5px;
    }

}

.header-article { //this weird nesting is needed for specificity
    .breadcrumbs a {
        text-decoration: none;
        border-bottom: 0;
    }
}

.industry {
    position: relative;
    padding: $huge-spacing;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    justify-content: space-between;

    @include media($medium) {
        padding: 50px $huge-spacing 0 $huge-spacing*2;
    }

    &.dark {
        color: $white;
        width: 100%;
        height: 100%;
        background: url('../img/industries/industry-background-1.png') center center no-repeat;
        background-size: cover;

        &:before {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-image: linear-gradient(to right, $black 100%, transparent);
            opacity: .9;

            @include media($small) {
                background-image: linear-gradient(to right, $black 30%, transparent);
            }

        }

        .info {
            -webkit-order: 1;
            order: 1;

            .subtitle {
                font-weight: light;
                text-transform: uppercase;
                margin-bottom: $tiny-spacing;
            }

            .title {
                font-size: em(35);
                font-weight: bold;
                text-transform: uppercase;
            }

            .bullets li {

                &:after {
                    background-color: $white;
                }

            }

            .story {
                color: $white;
            }

        }

        .device {
            -webkit-order: 2;
            order: 2;
            -webkit-align-self: flex-end;
            align-self: flex-end;
            margin-left: $base-spacing;
        }

    }

    &.light {
        color: $black;
        width: 100%;
        height: 100%;
        background: url('../img/industries/industry-background-2.png') center center no-repeat;
        background-size: cover;

        &:before {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-image: linear-gradient(to left, $white 100%, transparent);
            opacity: .9;

            @include media($small) {
                background-image: linear-gradient(to right, $white 50%, transparent);
            }

            @include media($medium) {
                background-image: linear-gradient(to left, $white 50%, transparent);
            }

        }

        .info {
            -webkit-order: 2;
            order: 2;

            .subtitle {
                font-weight: light;
                text-transform: uppercase;
                margin-bottom: $tiny-spacing;
            }

            .title {
                font-size: em(35);
                font-weight: bold;
                text-transform: uppercase;
            }

            .bullets li {

                &:after {
                    background-color: $black;
                }

            }

            .story {
                color: $black;
            }

        }

        .device {
            -webkit-order: 1;
            order: 1;
            -webkit-align-self: flex-end;
            align-self: flex-end;
            padding-left: $huge-spacing;
            margin-right: $huge-spacing;
        }

    }

    .info {
        -webkit-flex: auto;
        flex: auto;
        z-index: 1;
        padding-top: 11px;

        @include media($medium) {
            -webkit-flex: none;
            flex: none;
        }

        .bullets li {
            position: relative;
            list-style: none;
            @include iconic(before, circle-check, $highlight, 15px, true);

            &:before {
                position: relative;
                left: -15px;
                top: 1px;
            }

            &:after {
                content: '';
                position: absolute;
                top: 24px;
                left: -9px;
                width: 2px;
                height: 10px;
                opacity: .6;
            }

            &:last-child:after {
                content: none;
            }

        }

    }

    .device {
        z-index: 1;
        display: none;

        @include media($medium) {
            display: block;
        }

    }

    .button {
        margin-right: $base-spacing;
    }

    .story {
        display: inline-block;
        margin-top: $base-spacing;
        border-bottom: 2px dotted $highlight;

        @include media($small) {
            display: inline;
        }

    }

}

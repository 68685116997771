.metadata {
    display: block;
    font-size: 12px;
    font-size: .857142857rem;

    .metadata-author {
        font-weight: $bold;

        .avatar {
            display: inline;
            float: left;
            width: 20px;
            height: 20px;
            margin-top: -2px;
            margin-right: 5px;

            &:hover {
                border-color: rgba(0,0,0,.24);
            }
        }
    }

    .metadata-visibility {
        display: inline-block;
        width: 16px;
        height: 16px;
        text-align: center;
        @include equal-radius(100%);
        background-color: $red;
        box-shadow: 0 0 0 rgba(0,0,0,.16) inset,
            0 0 8px $red;
        padding: 2px;
        margin: 0 $tiny-spacing;

        svg.iconic {
            width: 12px;
            height: 12px;
            fill: $color-light-delta;
        }

        &.public {
            background-color: $green;
            box-shadow: 0 0 0 rgba(0,0,0,.16) inset,
                0 0 8px $green;
        }

        &.tooltip {

            .bug {
                top: -6px;
                right: 100%;
            }
        }
    }
}

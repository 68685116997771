.simple-tabs {
    display: none;
    position: relative;
    text-align: center;
    margin: $base-spacing auto;

    &.tabs-init {
        display: block;
    }

    ul {
        @extend .list-reset;
    }

    li {
        color: $blue;
        font-weight: $bold;
        text-transform: uppercase;
        letter-spacing: 1px;
        border: none;
        padding: 7px 10px;

        &:hover {
            cursor: pointer;
            color: $black;
        }

        &.active {
            color: $black;
        }
    }
}
